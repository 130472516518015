import { TimelineLite, Power2, CSSPlugin, ScrollToPlugin } from 'gsap/all';
import formDataEntries from 'form-data-entries';
import enquire from 'enquire.js';
import Module from '../lib/module';
import Breakpoint from '../lib/breakpoint';
import { EventAPI } from '../lib/event-helpers';
import Cookies from 'cookies-js';

// Make sure CSSPlugin and ScrollToPlugin are not dropped by tree shaking
const plugins = [CSSPlugin, ScrollToPlugin];

export default class ProgramFilter extends Module {
  static SIDEBAR_SELECTOR = '[data-role="filter-sidebar"]';
  static MAIN_SELECTOR = '[data-role="filter-main"]';
  static SIDEBAR_SECTION_SELECTOR = '[data-role="filter-sidebar-section"]';
  static MAIN_QUERY_TEXT_SELECTOR = '[data-role="filter-main-query-text"]';
  static MAIN_LOADING_SELECTOR = '[data-role="filter-main-loading"]';
  static MAIN_RESULTS_SELECTOR = '[data-role="filter-main-results"]';

  static EXPANDED_CLASS = 'is-expanded';
  static COLLAPSED_CLASS = 'is-collapsed';
  static INITIALLY_EXPANDED_CLASS = 'is-initially-expanded';
  static HIDDEN_CLASS = 'is-hidden';

  static DEFAULT_DURATION = 0.3;

  static MODES = {
    STATIC: 'static',
    FLUID: 'fluid',
  };

  setup() {
    this.mode = null;

    this.main = this.element.querySelector(this.constructor.MAIN_SELECTOR);
    this.sidebar = this.element.querySelector(this.constructor.SIDEBAR_SELECTOR);
    this.sidebarSections = this.element.querySelectorAll(this.constructor.SIDEBAR_SECTION_SELECTOR);

    this.mainQueryText = this.main.querySelector(this.constructor.MAIN_QUERY_TEXT_SELECTOR);
    this.mainLoading = this.main.querySelector(this.constructor.MAIN_LOADING_SELECTOR);
    this.mainResults = this.main.querySelector(this.constructor.MAIN_RESULTS_SELECTOR);

    this.eventApi = new EventAPI();
    this.timelines = {};
    this.enquireRules = [];
    const layoutBreakpoint = this.element.getAttribute('data-layout-breakpoint');

    if (layoutBreakpoint) {
      this.enquireRules.push({
        query: Breakpoint.mediaQueryFor(layoutBreakpoint, 'min'),
        handler: {
          match: this._setupFluidMode.bind(this),
          unmatch: this._resetFluidMode.bind(this),
        },
      });

      this.enquireRules.push({
        query: Breakpoint.mediaQueryFor(Breakpoint.getPreviousOf(layoutBreakpoint).name, 'max'),
        handler: {
          match: this._setupStaticMode.bind(this),
          unmatch: this._resetStaticMode.bind(this),
        },
      });
    } else {
      this._setupFluidMode();
    }

    this.bind();

    this._setInitialState();
  }

  bind() {
    this.enquireRules.forEach(rule => {
      enquire.register(rule.query, rule.handler);
    });

    this.eventApi.on(document, 'click', '[data-action="toggle-program-filter"]', event => {
      this.toggle();
    });

    this.eventApi.on(document, 'click', '[data-action="collapse-program-filter"]', event => {
      this.collapse();
    });

    this.eventApi.on(document, 'click', '[data-action="expand-program-filter"]', event => {
      this.expand();
    });

    this.eventApi.on(document, 'click', '[data-action="reset-filter-facet"]', event => {
      const target = event.target.closest('[data-action="reset-filter-facet"]');

      this.resetFacet(target.getAttribute('data-facet-name'));
    });

    this.eventApi.on(document, 'click', '[data-action="apply-filter"]', event => {
      this.apply();
    });

    this.eventApi.on(document, 'click', '[data-action="reset-filter"]', event => {
      this.reset();
    });

    this.bindAutoSubmit(this.main);
  }

  unbind() {
    this.enquireRules.forEach(rule => {
      enquire.unregister(rule.query, rule.handler);
    });

    this.eventApi.off(document, 'click', '[data-action="toggle-program-filter"]');
    this.eventApi.off(document, 'click', '[data-action="collapse-program-filter"]');
    this.eventApi.off(document, 'click', '[data-action="expand-program-filter"]');
    this.eventApi.off(document, 'click', '[data-action="reset-filter-facet"]');
    this.eventApi.off(document, 'click', '[data-action="apply-filter"]');
    this.eventApi.off(document, 'click', '[data-action="reset-filter"]');
    this.unbindAutoSubmit(this.main);
  }

  bindAutoSubmit(scope) {
    this.eventApi.on(scope, 'change', 'input, select, textarea', event => {
      // Elements without name will not be passed by URL
      // so they should not apply the filter when being changed
      if (event.target.matches('[name]')) {
        this.apply();
      }
    });
  }

  unbindAutoSubmit(scope) {
    this.eventApi.off(scope, 'change', 'input, select, textarea');
  }

  toggle(duration) {
    this._debug('ProgramFilter#toggle');

    if (this.element.classList.contains(this.constructor.COLLAPSED_CLASS)) {
      this.expand(duration);
    } else {
      this.collapse(duration);
    }
  }

  getSidebarContentTimeline(duration) {
    const timeline = new TimelineLite();

    if (this.mode === this.constructor.MODES.FLUID) {
      if (duration > 0) {
        timeline.staggerFromTo(this.sidebarSections, duration * 2, {
          opacity: 0,
          y: '-20px'
        }, {
          opacity: 1,
          y: '0px'
        }, duration * 2/3, duration);
      }
    }

    if (this.mode === this.constructor.MODES.STATIC) {
      if (duration > 0) {
        timeline.set(this.sidebarSections, { clearProps: 'y' });
        timeline.fromTo(this.sidebarSections, duration, { opacity: 0 }, { opacity: 1 });
      }
    }

    return timeline;
  }

  expand(duration) {
    this._debug('ProgramFilter#expand');

    Cookies.set('program_filter_is_expanded', '1');

    if (duration === undefined) {
      duration = this.constructor.DEFAULT_DURATION;
    }

    if (this.element.classList.contains(this.constructor.EXPANDED_CLASS) && duration > 0) {
      return;
    }

    if (this.mode === this.constructor.MODES.STATIC) {
      this.eventApi.on(document, 'click', (event) => {
        if (!this.element.contains(event.target)) { this.collapse(duration); }
      });
    }

    const timeline = this.timelines.expand = new TimelineLite({ paused: true });

    timeline.add(() => {
      this.sidebar.style.visibility = '';
    });

    if (this.mode === this.constructor.MODES.FLUID) {
      timeline.add(() => {
        this.element.style.transitionDuration = `${duration}s`;
      });
    }

    if (duration > 0 && window.pageYOffset > 0) {
      timeline.to(window, duration, { scrollTo: 0, ease: Power2.easeInOut });
    }

    timeline.addLabel('beforeTransition');

    if (this.mode === this.constructor.MODES.STATIC) {
      timeline.add(() => {
        document.documentElement.style.overflow = 'hidden';
        document.documentElement.style.height = '100%';
        document.body.classList.add('is-interaction-blocked');
      }, 'beforeTransition');

      timeline.to(document.body, duration, { x: `-${window.getComputedStyle(this.sidebar).width}` });
    }

    timeline.add(() => {
      this.element.classList.remove(this.constructor.COLLAPSED_CLASS);
      this.element.classList.add(this.constructor.EXPANDED_CLASS);
    }, 'beforeTransition');

    timeline.addLabel('afterTransition', `beforeTransition+=${duration}`);

    timeline.add(this.getSidebarContentTimeline(duration), 'afterTransition');

    if (this.mode === this.constructor.MODES.FLUID) {
      timeline.add(() => {
        this.element.style.transitionDuration = '0s';
      }, 'afterTransition');
    }

    timeline.add(() => {
      const scrollY = window.pageYOffset;

      // Set focus to sidebar to improve usage with keyboard
      this.sidebar.setAttribute('tabindex', '-1');
      this.sidebar.focus({ preventScroll: true });

      // Focusing may sometimes change the scroll position
      // make sure the scroll position stays the same
      document.documentElement.scrollTop = scrollY; // target IE
      document.body.scrollTop = scrollY; // target Safari
    }, 'afterTransition');

    if (this.timelines.collapse) {
      this.timelines.collapse.kill();
    }

    timeline.play();
  }

  collapse(duration) {
    this._debug('ProgramFilter#collapse');

    Cookies.set('program_filter_is_expanded', '0');

    if (duration === undefined) {
      duration = this.constructor.DEFAULT_DURATION;
    }

    if (this.element.classList.contains(this.constructor.COLLAPSED_CLASS) && duration > 0) {
      return;
    }

    if (this.mode === this.constructor.MODES.STATIC) {
      this.eventApi.off(document, 'click');
    }

    const timeline = this.timelines.collapse = new TimelineLite({ paused: true });

    timeline.add(this.getSidebarContentTimeline(duration).reverse());

    timeline.addLabel('beforeTransition');

    if (this.mode === this.constructor.MODES.STATIC) {
      timeline.add(() => {
        document.documentElement.style.overflow = '';
        document.documentElement.style.height = '';
        document.body.classList.remove('is-interaction-blocked');
      }, 'beforeTransition');

      timeline.to(document.body, duration, { x: 0 }, 'beforeTransition');
    }

    if (this.mode === this.constructor.MODES.FLUID) {
      timeline.add(() => {
        this.element.style.transitionDuration = `${duration}s`;
      }, 'beforeTransition');
    }

    timeline.add(() => {
      this.element.classList.remove(this.constructor.EXPANDED_CLASS);
      this.element.classList.add(this.constructor.COLLAPSED_CLASS);
    }, 'beforeTransition');

    timeline.addLabel('afterTransition', `beforeTransition+=${duration}`);

    if (this.mode === this.constructor.MODES.FLUID) {
      timeline.add(() => {
        this.element.style.transitionDuration = '0s';
      }, 'afterTransition');
    }

    if (this.mode === this.constructor.MODES.STATIC) {
      timeline.set(document.body, { clearProps: 'x' }, 'afterTransition');
    }

    timeline.add(() => {
      // Hide sidebare to remove ability to focus of elements with keyboard
      this.sidebar.style.visibility = 'hidden';
      this.sidebar.removeAttribute('tabindex');
    }, 'afterTransition')

    if (this.timelines.expand) {
      this.timelines.expand.kill();
    }

    timeline.play();
  }

  apply() {
    this._debug('ProgramFilter#apply');

    const form = this.element.closest('form');

    if (this.mode === this.constructor.MODES.STATIC) {
      this.collapse();
    }

    if (form) {
      const queryStringElements = [];

      for (const [key, value] of formDataEntries(form)) {
        queryStringElements.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
      }

      this.mainQueryText.classList.add(this.constructor.HIDDEN_CLASS);
      this.mainLoading.classList.remove(this.constructor.HIDDEN_CLASS);
      this.mainResults.classList.add(this.constructor.HIDDEN_CLASS);

      if (Turbolinks.supported) {
        Turbolinks.visit(form.action + '?' + queryStringElements.join('&'));
      } else {
        window.location.href = form.action + '?' + queryStringElements.join('&');
      }
    }
  }

  reset() {
    this._debug('ProgramFilter#reset');

    const scope = this.element.closest('form') || this.element;
    const inputs = scope.querySelectorAll(`[name="${name}"], [name="${name}[]"]`);

    this._clearInputs(inputs);

    this.apply();
  }

  resetFacet(name) {
    this._debug('ProgramFilter#resetFacet');

    const scope = this.element.closest('form') || this.element;
    const inputs = scope.querySelectorAll(`[name="${name}"], [name="${name}[]"]`);

    this._clearInputs(inputs);

    this.apply();
  }

  _clearInputs(inputs) {
    Array.from(inputs).forEach(input => {
      const tagName = input.tagName.toLowerCase();

      if (tagName === 'select') {
        input.value = '';
      } else if (tagName === 'textarea') {
        input.value = '';
      } else if (tagName === 'input') {
        switch (input.type.toLowerCase()) {
          case 'radio':
          case 'checkbox':
            input.checked = false;
            break;
          case 'text':
          case 'search':
          case 'hidden':
            input.value = '';
            break;
        }
      }
    });
  }

  _setInitialState() {
    const isExpanded = this.element.classList.contains(this.constructor.EXPANDED_CLASS);
    const isCollapsed = this.element.classList.contains(this.constructor.COLLAPSED_CLASS);
    const isInitiallyExpanded = this.element.classList.contains(this.constructor.INITIALLY_EXPANDED_CLASS);

    this.element.classList.remove(this.constructor.INITIALLY_EXPANDED_CLASS);

    if (isInitiallyExpanded) {
      if (this.mode === this.constructor.MODES.STATIC) {
        this.collapse(0);
      } else if (this.mode === this.constructor.MODES.FLUID) {
        this.expand(0);
      }
    } else if (isExpanded) {
      this.expand(0);
    } else if (isCollapsed || !isExpanded && !isCollapsed) {
      this.collapse(0);
    }
  }

  _setupFluidMode() {
    if (this.mode === this.constructor.MODES.FLUID) { return; }

    this.bindAutoSubmit(this.sidebar);

    this.mode = this.constructor.MODES.FLUID;
  }

  _resetFluidMode() {
    this.unbindAutoSubmit(this.sidebar);
  }

  _setupStaticMode() {
    if (this.mode === this.constructor.MODES.STATIC) { return; }

    if (this.element.classList.contains(this.constructor.EXPANDED_CLASS)) {
      document.documentElement.style.overflow = 'hidden';
      document.documentElement.style.height = '100%';
      document.body.classList.add('is-interaction-blocked');
      document.body.style.transform = `translateX(-${window.getComputedStyle(this.sidebar).width})`;
    }

    this.mode = this.constructor.MODES.STATIC;
  }

  _resetStaticMode() {
    if (this.element.classList.contains(this.constructor.EXPANDED_CLASS)) {
      document.documentElement.style.overflow = '';
      document.documentElement.style.height = '';
      document.body.classList.remove('is-interaction-blocked');
      document.body.style.transform = '';
    }
  }

  destroy() {
    this.unbind();
    this._resetFluidMode();
    this._resetStaticMode();
  }
}
