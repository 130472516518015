export function on(scope, event, target, callback, useCapture = false) {
  let delegatedCallback;

  if (typeof target === 'function') {
    delegatedCallback = target;
    useCapture = callback;
  } else {
    delegatedCallback = (event) => {
      if (!!event.target.closest(target)) {
        callback(event);
      }
    };
  }

  if (scope instanceof NodeList) {
    Array.from(scope).forEach(element => {
      element.addEventListener(event, delegatedCallback, useCapture);
    });
  } else {
    scope.addEventListener(event, delegatedCallback, useCapture);
  }

  return delegatedCallback;
}

export function off(scope, event, target, callback, useCapture = false) {
  if (scope instanceof NodeList) {
    Array.from(scope).forEach(element => {
      element.removeEventListener(event, callback, useCapture);
    });
  } else {
    scope.removeEventListener(event, callback, useCapture);
  }
}

export function trigger(scope, event, detail) {
  const customEvent = new CustomEvent(event, {
    bubbles: true,
    cancelable: true,
    detail: detail
  });

  if (scope instanceof NodeList) {
    Array.from(scope).forEach(element => {
      element.dispatchEvent(customEvent);
    });
  } else {
    scope.dispatchEvent(customEvent);
  }
}

export class EventAPI {
  constructor() {
    this._bindings = {};
  }

  on(scope, event, target, callback, useCapture) {
    const bindingId = this._buildBindingId(scope, event, target);

    return this._bindings[bindingId] = on(scope, event, target, callback, useCapture);
  }

  off(scope, event, target, callback, useCapture) {
    const bindingId = this._buildBindingId(scope, event, target);
    if (!callback) {
      callback = this._bindings[bindingId];
    }

    off(scope, event, target, callback, useCapture);

    delete this._bindings[bindingId];
  }

  trigger(scope, event, detail) {
    trigger(...arguments);
  }

  _buildBindingId(scope, event, target) {
    const type = (scope instanceof HTMLDocument) ? 'document' : (scope instanceof Window) ? 'window' : 'element';
    const suffix = (typeof target === 'function' ? null : target);

    return [
      type,
      event.split(' ').join('-'),
      suffix,
    ].filter(part => {
      return !!part;
    }).join('#');
  }
}
