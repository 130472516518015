import Module from '../lib/module';
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm';
import PhotoSwipe from 'photoswipe/dist/photoswipe.esm';

export default class FullscreenLightbox extends Module {
  static ITEM_SELECTOR = '[data-role="lightbox-item"]';
  static MAXIMUM_IMAGE_DIMENSION = 2000;

  setup() {
    this.element.querySelectorAll(`${this.constructor.ITEM_SELECTOR}`).forEach(item => {
      const imageElement = item.querySelector('img');
      const image = new Image();

      image.onload = (event) => {
        const dimensions = this._detectImageDimensions(image);

        item.dataset['pswpWidth'] = dimensions.width;
        item.dataset['pswpHeight'] = dimensions.height;
      };
      image.src = imageElement.src;
    });

    this.lightbox = new PhotoSwipeLightbox({
      gallery: this.element,
      children: this.constructor.ITEM_SELECTOR,
      pswpModule: PhotoSwipe,
    });

    this.bind();
  }

  bind() {
    this.lightbox.init();
  }

  unbind() {
    this.lightbox.destroy();
  }

  destroy() {
    this.unbind();
  }

  _detectImageDimensions(image) {
    const maximumImageDimension = this.constructor.MAXIMUM_IMAGE_DIMENSION;
    let width = image.naturalWidth;
    let height = image.naturalHeight;

    if (width > height) {
      return {
        width: maximumImageDimension,
        height: Math.round(height/width * maximumImageDimension),
      };
    } else {
      return {
        width: Math.round(width/height * maximumImageDimension),
        height: maximumImageDimension,
      }
    }
  }
}
