import { TimelineLite, Power2, CSSPlugin } from 'gsap/all';
import Module from '../lib/module';
import { EventAPI } from '../lib/event-helpers';

// Make sure CSSPlugin is not dropped by tree shaking
const plugins = [CSSPlugin];

export default class BurgerMenu extends Module {
  static BACKGROUND_LAYER_SELECTOR = '[data-role="burger-menu-background"]';
  static CONTENT_SELECTOR = '[data-role="burger-menu-content"]';
  static HEADER_LOGO_SELECTOR = '[data-role="burger-menu-header-logo"]';
  static HEADER_CONTROL_SELECTOR = '[data-role="burger-menu-header-control"]';
  static HEADER_CONTENT_SELECTOR = '[data-role="burger-menu-header-content"]';
  static BODY_SELECTOR = '[data-role="burger-menu-body"]';
  static FOOTER_SELECTOR = '[data-role="burger-menu-footer"]';
  static SECTION_SELECTOR = '[data-role="burger-menu-section"]';

  static ONSCREEN_CLASS = 'is-onscreen';

  setup() {
    this.eventApi = new EventAPI();

    this.background = this.element.querySelector(this.constructor.BACKGROUND_LAYER_SELECTOR);
    this.content = this.element.querySelector(this.constructor.CONTENT_SELECTOR);
    this.body = this.element.querySelector(this.constructor.BODY_SELECTOR);
    this.footer = this.element.querySelector(this.constructor.FOOTER_SELECTOR);
    this.sections = this.element.querySelectorAll(this.constructor.SECTION_SELECTOR);
    this.headerLogo = this.element.querySelector(this.constructor.HEADER_LOGO_SELECTOR);
    this.headerControl = this.element.querySelector(this.constructor.HEADER_CONTROL_SELECTOR);
    this.headerContent = document.querySelector(this.constructor.HEADER_CONTENT_SELECTOR);

    this.primaryTimeline = this._defineTimeline();

    this.bind();
  }

  bind() {
    this.eventApi.on(document, 'click', '[data-action="toggle-burger-menu"]', event => {
      this.toggle();
    });

    this.eventApi.on(document, 'click', '[data-action="show-burger-menu"]', event => {
      this.show();
    });

    this.eventApi.on(document, 'click', '[data-action="hide-burger-menu"]', event => {
      this.hide();
    });
  }

  unbind() {
    this.eventApi.off(document, 'click', '[data-action="toggle-burger-menu"]');
    this.eventApi.off(document, 'click', '[data-action="show-burger-menu"]');
    this.eventApi.off(document, 'click', '[data-action="hide-burger-menu"]');
  }

  toggle() {
    this._debug('BurgerMenu#toggle');

    if (this.element.classList.contains(this.constructor.ONSCREEN_CLASS)) {
      this.hide();
    } else {
      this.show();
    }
  }

  show(duration) {
    this._debug('BurgerMenu#show');

    if (duration === undefined) {
      duration = this.primaryTimeline.duration();
    }

    const timeline = new TimelineLite({ paused: true });
    timeline.add(() => {
      document.documentElement.style.overflow = 'hidden';
      this.element.classList.add(this.constructor.ONSCREEN_CLASS);
    });
    timeline.add(this.primaryTimeline.duration(duration).timeScale(1).restart());

    timeline.play();
  }

  hide(duration) {
    this._debug('BurgerMenu#hide');

    if (duration === undefined) {
      duration = this.primaryTimeline.duration();
    }

    const timeline = new TimelineLite({ paused: true });
    timeline.add(this.primaryTimeline.duration(duration).timeScale(1.33).reverse());
    timeline.add(() => {
      this.element.classList.remove(this.constructor.ONSCREEN_CLASS);
      document.documentElement.style.overflow = '';
    });

    timeline.play();
  }

  destroy() {
    this.unbind();

    if (this.element.classList.contains(this.constructor.ONSCREEN_CLASS)) {
      this.hide(0);
    }
  }

  _defineTimeline() {
    const timeline = new TimelineLite({ paused: true });

    timeline.set(this.element, { display: 'block' });
    timeline.fromTo(this.background, 0.8, { rotation: '90deg' }, { rotation: '0deg', ease: Power2.Ease }, '+=0.2');
    timeline.fromTo(this.headerLogo, 0.2, { opacity: 0, y: '20px' }, { opacity: 1, y: '0px' }, '-=0.3');
    timeline.fromTo(this.headerControl, 0.4, { opacity: 0, y: '-40px' }, { opacity: 1, y: '0px' }, '-=0.2');
    timeline.fromTo(this.headerContent, 0.4, { opacity: 0 }, { opacity: 1 }, '-=0.2');
    timeline.staggerFromTo(this.sections, 0.3, { opacity: 0, y: '20px' }, { opacity: 1, y: '0px' }, 0.05, '-=0.4');
    timeline.fromTo(this.footer, 0.4, { opacity: 0 }, { opacity: 1 }, '-=0.2');

    return timeline;
  }
}
