import Module from '../lib/module';
import { EventAPI } from '../lib/event-helpers';

export default class GiftCardPreview extends Module {
  static AMOUNT_OUTPUT_SELECTOR = '[data-role="amount-output"]';
  static MESSAGE_OUTPUT_SELECTOR = '[data-role="message-output"]';

  static PLACEHOLDER_CLASS = 'form-group__placeholder';

  setup() {
    this.eventApi = new EventAPI();

    const amountSourceId = this.element.getAttribute('data-amount-source-id');
    this.amountSource = document.querySelector(`#${amountSourceId}`);
    this.amountOutput = this.element.querySelector(this.constructor.AMOUNT_OUTPUT_SELECTOR);

    this.update();
    this.bind();
  }

  bind() {
    this.eventApi.on(document, 'change', `[name="${this.amountSource.name}"]`, event => {
      this.update();
    });
  }

  unbind() {
    this.eventApi.off(document, 'change', `[name="${this.amountSource.name}"]`);
  }

  update() {
    this._debug('GiftCardPreview#update');

    let amountValue = this.amountSource.value;

    this.amountOutput.innerHTML = amountValue;
  }

  destroy() {
    this.unbind();
  }
}
