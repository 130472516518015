import enquire from 'enquire.js';
import { TweenLite, Power2 } from 'gsap/all';
import Module from '../lib/module';
import Breakpoint from '../lib/breakpoint';
import { EventAPI } from '../lib/event-helpers';

export default class PanableContent extends Module {
  setup() {
    this.eventApi = new EventAPI();

    this.isClickBlocked = false;
    this.initialPosition = null;
    this.initialBoundingBox = null;
    this.parentBoundingBox = null;

    this.enquireRules = [];
    const breakpoint = this.element.getAttribute('data-breakpoint-limit');

    if (breakpoint) {
      this.enquireRules.push({
        query: Breakpoint.mediaQueryFor(breakpoint.split(':')[0], breakpoint.split(':')[1]),
        handler: {
          match: this.activate.bind(this),
          unmatch: this.deactivate.bind(this),
        },
      });
    } else {
      this.activate();
    }

    this.bind();
  }

  activate() {
    this.bindActions();
  }

  deactivate() {
    this.unbindActions();
  }

  destroy() {
    this.unbind();

    if (this.enquireRules.length === 0) {
      this.deactivate();
    }
  }

  bind() {
    this.enquireRules.forEach(rule => {
      enquire.register(rule.query, rule.handler);
    });
  }

  unbind() {
    this.enquireRules.forEach(rule => {
      enquire.unregister(rule.query, rule.handler);
    });
  }

  bindActions() {
    const onStart = (event) => {
      const unified = this._unifyEvent(event);

      this.isClickBlocked = false;
      this.initialPosition = { x: unified.clientX, y: unified.clientY };
      this.initialBoundingBox = this.element.getBoundingClientRect();
      this.parentBoundingBox = this.element.parentNode.getBoundingClientRect();
    };

    const onMove = (event) => {
      if (!this.initialPosition) { return; }

      const unified = this._unifyEvent(event);
      const currentPosition = { x: unified.clientX, y: unified.clientY };

      this.pan({
        x: currentPosition.x - this.initialPosition.x,
        y: currentPosition.y - this.initialPosition.y,
      });
    };

    const onEnd = (event) => {
      if (!this.initialPosition) { return; }

      this.snapBack();

      if (this.isClickBlocked) {
        event.preventDefault();

        // Block click event for 500ms to make sure
        // to even catch slow ghost clicks on mobile devices
        window.setTimeout(() => {
          this.eventApi.off(this.element, 'click');
        }, 500);
      }

      this.isClickBlocked = false;
      this.initialPosition = null;
      this.initialBoundingBox = null;
      this.parentBoundingBox = null;
    };

    this.eventApi.on(this.element, 'mousedown', onStart);
    this.eventApi.on(document, 'mousemove', onMove);
    this.eventApi.on(document, 'mouseup', onEnd);

    this.eventApi.on(this.element, 'touchstart', onStart);
    this.eventApi.on(this.element, 'touchmove', onMove);
    this.eventApi.on(this.element, 'touchend', onEnd);
  }

  unbindActions() {
    this.eventApi.off(this.element, 'mousedown');
    this.eventApi.off(document, 'mousemove');
    this.eventApi.off(document, 'mouseup');

    this.eventApi.off(this.element, 'touchstart');
    this.eventApi.off(this.element, 'touchmove');
    this.eventApi.off(this.element, 'touchend');

    this.eventApi.off(this.element, 'click');
  }

  _unifyEvent(event) {
    return event.changedTouches ? event.changedTouches[0] : event;
  }

  pan(delta) {
    this._debug('PanableContent#pan');

    const directionX = delta.x < 0 ? 'left' : 'right';
    const offsetLeft = this.initialBoundingBox.left - this.parentBoundingBox.left;
    const offsetRight = this.parentBoundingBox.right - this.initialBoundingBox.right;
    let deltaX = Math.floor(delta.x);
    const absoluteDeltaX = Math.abs(deltaX);

    // If element is moved more than 10px
    // there should be no click event after releasing/dropping the element
    if (absoluteDeltaX > 10 && !this.isClickBlocked) {
      this.isClickBlocked = true;

      this.eventApi.on(this.element, 'click', (event) => {
        event.preventDefault();
      });
    }

    if (directionX === 'left') {
      if (offsetRight === 0 && offsetRight > deltaX) {
        deltaX = offsetRight - (Math.sqrt(absoluteDeltaX - offsetRight) * 3);
      } else if (offsetRight < 0 && Math.abs(offsetRight) < absoluteDeltaX) {
        deltaX = offsetRight - (Math.sqrt(absoluteDeltaX - (offsetRight * -1)) * 3);
      }
    } else if (directionX === 'right') {
      if (offsetLeft === 0 && offsetLeft < deltaX) {
        deltaX = offsetLeft + (Math.sqrt(absoluteDeltaX - offsetLeft) * 3);
      } else if (offsetLeft < 0 && Math.abs(offsetLeft) < absoluteDeltaX) {
        deltaX = (offsetLeft * -1) + (Math.sqrt(absoluteDeltaX - (offsetLeft * -1)) * 3);
      }
    }

    document.body.style.overflowX = 'hidden';
    TweenLite.set(this.element, { x: deltaX });
  }

  snapBack() {
    this._debug('PanableContent#snapBack');

    document.body.style.overflowX = '';
    TweenLite.to(this.element, 0.3, { x: 0, ease: Power2.easeOut });
  }
}
