export function elementInViewport(element, intersectionFactor = 1) {
  // intersectionFactor can be a value between 0 and 1
  // e.g. 1/2 means that element is assumed to be 'in' viewport
  // if it covers at least half (1/2) of screen height

  const bounds = element.getBoundingClientRect();
  const windowHeight = Math.round(window.innerHeight || document.documentElement.clientHeight);

  return (
    (Math.round(bounds.top) >= 0 && Math.round(bounds.top) <= (1 - intersectionFactor) * windowHeight) ||
    (Math.round(bounds.bottom) <= windowHeight && Math.round(bounds.bottom) >= intersectionFactor * windowHeight) ||
    (Math.round(bounds.top) <= 0 && Math.round(bounds.bottom) >= windowHeight)
  );
}

export function elementOffsetTop(element) {
  const viewportOffsetTop = window.pageYOffset;
  const elementOffsetTopInViewport = element.getBoundingClientRect().top;

  return viewportOffsetTop + elementOffsetTopInViewport;
}


export function createElementFromHTML(markup) {
  var div = document.createElement('div');
  div.innerHTML = markup.trim();

  return div.firstChild;
}

// Based on https://gist.github.com/micjamking/6e9886609d40653b3aef107845589744
export function makeRequest(options) {
  const type = options.type;
  let url = options.url;
  let data = options.data;

  // Add header to allow correct usage of `request.xhr?` in controller
  // https://api.rubyonrails.org/classes/ActionDispatch/Request.html#method-i-xml_http_request-3F
  const headers = Object.assign({
    'X-Requested-With': 'XMLHttpRequest',
  }, options.headers || {});

  return new Promise(function(resolve, reject) {
    const xhr = new XMLHttpRequest();

    // Stringify data if passed as an object
    if (data && typeof data === 'object') {
      data = Object.keys(data).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
      }).join('&');
    }

    // Add data to URL if request will be made via GET
    if (type === 'GET' && data) {
      if (url.indexOf('?') < 0) {
        url += '?' + data;
      } else {
        url += '&' + data;
      }
    }

    xhr.open(type, url);

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      }
    };

    xhr.onerror = () => {
      reject({
        status: xhr.status,
        statusText: xhr.statusText
      });
    };

    Object.keys(headers).forEach((key) => {
      xhr.setRequestHeader(key, headers[key]);
    });

    if (type === 'GET') {
      xhr.send();
    } else {
      xhr.send(data);
    }
  });
}
