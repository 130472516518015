import Module from '../lib/module';
import { EventAPI } from '../lib/event-helpers';
import { createElementFromHTML } from '../lib/utils';

// Inspired by https://www.w3.org/TR/wai-aria-practices/examples/combobox/aria1.1pattern/listbox-combo.html

const GROUP_TEMPLATE = function(data) {
  return `
  <li role="group" data-role="autocomplete-list-group">
    <span class="listbox__group-header small-uppercase-header">
      ${data.label}
    </span>

    <ul class="listbox__group-items" data-role="autocomplete-list-group-list">
    </ul>
  </li>
  `;
}

const ITEM_TEMPLATE = function(data) {
  const pluralize = function(count, singular, other) {
    return `${count} ${count === 1 ? singular : other}`;
  }

  return `
  <li class="listbox__item"
    role="option"
    data-role="autocomplete-list-item">

    <span>${data.option.label}</span>
    <span>${pluralize(data.option.count, 'Kurs', 'Kurse')}</span>
  </li>
  `;
}

export default class Autocomplete extends Module {
  static INPUT_SELECTOR = '[data-role="autocomplete-input"]';
  static LIST_SELECTOR = '[data-role="autocomplete-list"]';
  static LIST_GROUP_SELECTOR = '[data-role="autocomplete-list-group"]';
  static LIST_GROUP_LIST_SELECTOR = '[data-role="autocomplete-list-group-list"]';
  static LIST_ITEM_SELECTOR = '[data-role="autocomplete-list-item"]';
  static EMPTY_RESULT_SELECTOR = '[data-role="autocomplete-empty-result"]';
  static NAMED_INPUT_SELECTOR = '[data-role="autocomplete-named-input"]';

  static HIDDEN_CLASS = 'is-hidden';
  static FOCUSED_CLASS = 'is-focused';

  static DEFAULT_DURATION = 0.3;

  static KEYCODE_UP = 38;
  static KEYCODE_DOWN = 40;
  static KEYCODE_ESC = 27;
  static KEYCODE_RETURN = 13;
  static KEYCODE_BACKSPACE = 8;
  static KEYCODE_TAB = 9;

  setup() {
    this.eventApi = new EventAPI();

    this.shouldAutoSelect = true;
    this.willAutoSubmit = this.element.getAttribute('data-autosubmit') === 'true';
    this.focusedIndex = null;
    this.selectedItems = [];

    this.input = this.element.querySelector(this.constructor.INPUT_SELECTOR);
    this.list = this.element.querySelector(this.constructor.LIST_SELECTOR);
    this.namedInputs = this.element.querySelectorAll(this.constructor.NAMED_INPUT_SELECTOR);
    this.emptyResult = this.element.querySelector(this.constructor.EMPTY_RESULT_SELECTOR);

    this.list.classList.add(this.constructor.HIDDEN_CLASS);

    // The main input should not have a 'name' attribute if items have
    // mixed types and will be synchronized via named inputs
    // Having a 'name' attribute will submit the form with wrong value
    // if the form is configured to submit on change
    if (this.input.name && this.namedInputs.length > 1) {
      this.input.removeAttribute('name');
    }

    try {
      this.dataGroups = JSON.parse(this.list.getAttribute('data-groups'));
    } catch (exception) {
      this.dataGroups = [];
    }

    // Build markup for groups and options
    this.dataGroups.forEach(group => {
      group.element = createElementFromHTML(GROUP_TEMPLATE({ label: group.label }));
      group.options = group.options.map(option => {
        return Object.assign(option, {
          element: createElementFromHTML(ITEM_TEMPLATE({ option: option })),
          group: group.name,
          canBeListed: false,
          matchesCurrentQuery: false,
        });
      })
    });

    // Append groups/options to DOM
    this.dataGroups.forEach(group => {
      const groupList = group.element.querySelector(this.constructor.LIST_GROUP_LIST_SELECTOR);
      group.options.forEach(option => groupList.appendChild(option.element));
      this.list.appendChild(group.element);
    });

    this.initializeFromNamedInputs();
    this.bind();
  }

  get dataOptions() {
    return this.dataGroups.reduce((previous, current) => {
      return previous.concat(current.options);
    }, []);
  }

  bind() {
    this.eventApi.on(document.body, 'click', event => {
      if (event.target === this.input || this.element.contains(event.target)) {
        return;
      }

      this.hideResults();
      this.hideEmptyResult();
    });

    this.eventApi.on(this.element, 'keyup', this.constructor.INPUT_SELECTOR, event => {
      const key = event.which || event.keyCode;

      switch (key) {
        case this.constructor.KEYCODE_UP:
        case this.constructor.KEYCODE_DOWN:
        case this.constructor.KEYCODE_ESC:
        case this.constructor.KEYCODE_RETURN:
          event.preventDefault();
          return;
        default:
          this.search(this.input.value);
          this.updateResults();
      }
    });

    this.eventApi.on(this.element, 'keydown', this.constructor.INPUT_SELECTOR, this.handleKeyboardInteraction.bind(this));

    this.eventApi.on(this.element, 'mouseover', this.constructor.LIST_ITEM_SELECTOR, event => {
      const target = event.target.closest(this.constructor.LIST_ITEM_SELECTOR);

      Array.from(this.dataOptions).forEach(item => {
        item.element.classList.remove(this.constructor.FOCUSED_CLASS);
      });

      const item = this.dataOptions.find(item => item.element === target);
      this.focusItem(item);
    });

    this.eventApi.on(this.element, 'focus', this.constructor.INPUT_SELECTOR, event => {
      this.focus();
    });

    this.eventApi.on(this.element, 'blur', this.constructor.INPUT_SELECTOR, event => {
      this.blur();
    });

    this.eventApi.on(this.element, 'click', this.constructor.LIST_SELECTOR, event => {
      const target = event.target.closest(this.constructor.LIST_ITEM_SELECTOR);
      this.clickItem(target);
    });
  }

  unbind() {
    this.eventApi.off(document.body, 'click');
    this.eventApi.off(this.element, 'keyup', this.constructor.INPUT_SELECTOR);
    this.eventApi.off(this.element, 'keydown', this.constructor.INPUT_SELECTOR);
    this.eventApi.off(this.element, 'focus', this.constructor.INPUT_SELECTOR);
    this.eventApi.off(this.element, 'blur', this.constructor.INPUT_SELECTOR);
    this.eventApi.off(this.element, 'click', this.constructor.LIST_SELECTOR);
    this.eventApi.off(this.element, 'mouseover', this.constructor.LIST_ITEM_SELECTOR);
  }

  search(value) {
    this._debug('Autocomplete#search');

    const items = this.dataOptions;

    items.filter(item => {
      const itemValue = item.label.toLowerCase()
      const matchesQuery = itemValue.match(new RegExp(`(^|\\s)${value}`, 'gi')) !== null;

      item.matchesCurrentQuery = matchesQuery;
      item.canBeListed = matchesQuery && item.count > 0;
    });

    this.resultCount = items.filter(item => item.canBeListed).length;
  }

  updateResults() {
    this._debug('Autocomplete#updateResults');

    const items = this.dataOptions;
    let wasFirstOptionSelected = false;

    // Update visibility of items
    items.forEach(item => {
      const isConsideredToBeShown = item.canBeListed;

      if (isConsideredToBeShown) {
        item.element.classList.remove(this.constructor.HIDDEN_CLASS);
      } else {
        item.element.classList.add(this.constructor.HIDDEN_CLASS);
      }

      if (this.shouldAutoSelect && isConsideredToBeShown && !wasFirstOptionSelected) {
        this.focusItem(item);
        wasFirstOptionSelected = true;
      }
    });

    // Update visibility of groups
    Array.from(this.dataGroups).forEach(group => {
      const selectableGroupItems = group.options.filter(item => item.group === group.name && item.canBeListed);

      if (selectableGroupItems.length > 0) {
        group.element.classList.remove(this.constructor.HIDDEN_CLASS);
      } else {
        group.element.classList.add(this.constructor.HIDDEN_CLASS);
      }
    });

    if (this.input.value.trim().length > 0 && this.resultCount > 0) {
      this.showResults();
    } else {
      this.hideResults();
      this.clearSelection();
    }

    if (this.input.value.trim().length > 0 && this.resultCount === 0) {
      this.showEmptyResult();
    } else {
      this.hideEmptyResult();
    }
  }

  clearFocus() {
    this._debug('Autocomplete#clearFocus');

    this.dataOptions.forEach(item => {
      item.element.classList.remove(this.constructor.FOCUSED_CLASS);
      item.element.removeAttribute('aria-selected');
    });

    this.input.setAttribute('aria-activedescendant', '');
  }

  focusItem(item) {
    this._debug('Autocomplete#focusItem');

    this.clearFocus();
    this.focusedIndex = this.dataOptions.indexOf(item);
    item.element.setAttribute('aria-selected', 'true');
    item.element.classList.add(this.constructor.FOCUSED_CLASS);
    this.input.setAttribute('aria-activedescendant', item.element.getAttribute('id'));
  }

  focusNextItem() {
    this._debug('Autocomplete#focusNextItem');

    let index, nextItem;
    const items = this.dataOptions;
    const focusedIndex = this.focusedIndex;
    const focusedItem = items[focusedIndex];
    const filteredItems = items.filter(item => item.canBeListed);
    const nextFilteredItem = filteredItems[filteredItems.indexOf(focusedItem) + 1];
    const lastFilteredItem = filteredItems[filteredItems.length - 1];
    const firstFilteredItem = filteredItems[0];
    const isLastItem = focusedIndex === items.indexOf(lastFilteredItem);

    if (focusedIndex === null || isLastItem) {
      index = items.indexOf(firstFilteredItem);
    } else if (nextFilteredItem) {
      index = items.indexOf(nextFilteredItem);
    }

    nextItem = items[index];
    if (nextItem !== undefined) {
      this.focusItem(nextItem);
    }
  }

  focusPreviousItem() {
    this._debug('Autocomplete#focusPreviousItem');

    let index, previousItem;
    const items = this.dataOptions;
    const focusedIndex = this.focusedIndex;
    const focusedItem = items[focusedIndex];
    const filteredItems = items.filter(item => item.canBeListed);
    const previousFilteredItem = filteredItems[filteredItems.indexOf(focusedItem) - 1];
    const lastFilteredItem = filteredItems[filteredItems.length - 1];
    const firstFilteredItem = filteredItems[0];
    const isFirstItem = focusedIndex === items.indexOf(firstFilteredItem);

    if (focusedIndex === null || isFirstItem) {
      index = items.indexOf(lastFilteredItem);
    } else if (previousFilteredItem) {
      index = items.indexOf(previousFilteredItem);
    }

    previousItem = items[index];
    if (previousItem !== undefined) {
      this.focusItem(previousItem);
    }
  }

  handleKeyboardInteraction(event) {
    const key = event.which || event.keyCode;

    switch (key) {
      case this.constructor.KEYCODE_UP:
        event.preventDefault();
        this.focusPreviousItem();
        break;
      case this.constructor.KEYCODE_DOWN:
        event.preventDefault();
        this.focusNextItem();
        break;
      case this.constructor.KEYCODE_ESC:
        this.hideResults();
        this.hideEmptyResult();
        this.clearSelection();
        this.input.value = '';
        break;
      case this.constructor.KEYCODE_RETURN:
        this.selectItem(this.dataOptions[this.focusedIndex]);
        break;
      case this.constructor.KEYCODE_TAB:
        this.blur();
        this.hideResults();
        this.hideEmptyResult();
        break;
    }
  }

  clickItem(element) {
    this._debug('Autocomplete#clickItem');

    const item = this.dataOptions.find(item => item.element === element);
    this.selectItem(item);
  }

  selectItem(item) {
    this._debug('Autocomplete#selectItem');

    const form = this.element.closest('form');

    if (item) {
      this.input.value = item.label;
      this.hideResults();
      this.hideEmptyResult();
      this.selectedItems = [item];
      this.updateNamedInputs();
    }

    if (form && this.willAutoSubmit) {
      form.submit();
    }
  }

  clearSelection() {
    this.selectedItems = [];
    this.updateNamedInputs();
  }

  focus() {
    this._debug('Autocomplete#focus');

    this.search(this.input.value);
    this.updateResults();
  }

  blur() {
    this._debug('Autocomplete#blur');

    if (this.focusedIndex !== null) {
      this.selectItem(this.dataOptions[this.focusedIndex]);
    } else {
      this.input.value = '';
      this.clearSelection();
    }
  }

  showResults() {
    this._debug('Autocomplete#showResults');

    this.list.classList.remove(this.constructor.HIDDEN_CLASS);
    this.element.setAttribute('aria-expanded', 'true');
    this.hideEmptyResult();
  }

  hideResults() {
    this._debug('Autocomplete#hideResults');

    this.list.classList.add(this.constructor.HIDDEN_CLASS);
    this.element.setAttribute('aria-expanded', 'false');

    this.focusedIndex = null;
    this.resultsCount = 0;
    this.input.setAttribute('aria-activedescendant', '');
  }

  hideEmptyResult() {
    this._debug('Autocomplete#hideEmptyResult');

    if (!this.emptyResult) { return; }

    this.emptyResult.innerText = '';
    this.emptyResult.classList.add(this.constructor.HIDDEN_CLASS);
  }

  showEmptyResult() {
    this._debug('Autocomplete#showEmptyResult');

    if (!this.emptyResult) { return; }

    this.emptyResult.innerText = `Keine Treffer zu "${this.input.value.trim()}"`;
    this.emptyResult.classList.remove(this.constructor.HIDDEN_CLASS);
  }

  initializeFromNamedInputs() {
    const dataGroups = this.dataGroups;

    Array.from(this.namedInputs).forEach(input => {
      let selectedItem;

      dataGroups.forEach(group => {
        if (input.name.match(new RegExp(`(${group.name}|${group.name}\[\])`, 'gi'))) {
          selectedItem = group.options.find(option => input.value === option.value);
        }
      });

      if (selectedItem) {
        this.input.value = selectedItem.label;
      } else {
        input.value = '';
        input.disabled = true;
      }
    });
  }

  updateNamedInputs() {
    const selectedItems = this.selectedItems;
    const changedInputs = [];

    Array.from(this.namedInputs).forEach(input => {
      const inputValue = input.value;
      const selectedItem = selectedItems.find(item => {
        return input.name.match(new RegExp(`(${item.group}|${item.group}\[\])`, 'gi'));
      });

      if (selectedItem) {
        input.value = selectedItem.value;
        input.disabled = false;

      } else {
        input.value = '';
        input.disabled = true;
      }

      if (inputValue !== input.value) {
        changedInputs.push(input);
      }
    });

    changedInputs.forEach(input => {
      this.eventApi.trigger(input, 'change');
    });
  }

  destroy() {
    this.unbind();
  }
}
