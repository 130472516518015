import enquire from 'enquire.js';
import Swiper from 'swiper';
import Module from '../lib/module';
import Breakpoint from '../lib/breakpoint';
import { EventAPI } from '../lib/event-helpers';

export default class PanelSlider extends Module {
  static ROOT_SELECTOR = '[data-role="slider-root"]';
  static CONTAINER_SELECTOR = '[data-role="slider-slide-container"]';
  static SLIDE_SELECTOR = '[data-role="slider-slide"]';
  static PAGINATION_SELECTOR = '[data-role="slider-pagination"]';
  static CONTROL_PREVIOUS_SELECTOR = '[data-role="slider-control-previous"]';
  static CONTROL_NEXT_SELECTOR = '[data-role="slider-control-next"]';

  setup() {
    this.root = this.element.querySelector(this.constructor.ROOT_SELECTOR);
    this.container = this.element.querySelector(this.constructor.CONTAINER_SELECTOR);
    this.slides = this.element.querySelectorAll(this.constructor.SLIDE_SELECTOR);
    this.pagination = this.element.querySelector(this.constructor.PAGINATION_SELECTOR);
    this.controlPrevious = this.element.querySelector(this.constructor.CONTROL_PREVIOUS_SELECTOR);
    this.controlNext = this.element.querySelector(this.constructor.CONTROL_NEXT_SELECTOR);

    this.eventApi = new EventAPI();
    this.enquireRules = [
      {
        query: Breakpoint.mediaQueryFor('md', 'min'),
        handler: {
          match: this._setupSwiper.bind(this),
          unmatch: this._destroySwiper.bind(this),
        },
      },
    ];

    this.bind();
  }

  bind() {
    this.enquireRules.forEach(rule => {
      enquire.register(rule.query, rule.handler);
    });

    this.eventApi.on(this.element, 'click', '[data-action="navigate-previous"]', event => {
      this.slidePrevious();
    });

    this.eventApi.on(this.element, 'click', '[data-action="navigate-next"]', event => {
      this.slideNext();
    });
  }

  unbind() {
    this.enquireRules.forEach(rule => {
      enquire.unregister(rule.query, rule.handler);
    });

    this.eventApi.off(this.element, 'click', '[data-action="navigate-next"]');
    this.eventApi.off(this.element, 'click', '[data-action="navigate-previous"]');
  }

  slideNext() {
    this._debug('PanelSlider#slideNext');

    this.swiper.slideNext();
  }

  slidePrevious() {
    this._debug('PanelSlider#slidePrevious');

    this.swiper.slidePrev();
  }

  destroy() {
    this.unbind();
    this._destroySwiper();
  }

  _setupSwiper() {
    const breakpoints = {};
    breakpoints[parseInt(Breakpoint.get('lg').maxWidth, 10)] = {
      slidesPerView: 3,
      slidesPerGroup: 3,
    }

    this._setupSwiperMarkup();

    const swiper = new Swiper(this.root, {
      init: false,
      speed: 500,
      loop: true,
      slidesPerView: 4,
      slidesPerGroup: 4,
      spaceBetween: 16,
      breakpoints: breakpoints,
    });

    swiper.on('init slideChangeTransitionEnd', () => {
      if (swiper.params.loop) { return; }

      if (this.controlPrevious) {
        this.controlPrevious.disabled = swiper.isBeginning;
      }
      if (this.controlNext) {
        this.controlNext.disabled = swiper.isEnd;
      }
    });

    swiper.init();

    this.swiper = swiper;
  }

  _destroySwiper() {
    if (this.swiper) {
      this.swiper.destroy(true, true);
      this.swiper = null;
    }

    this._removeSwiperMarkup();
  }

  _setupSwiperMarkup() {
    this.root.classList.add('swiper-container');
    this.container.classList.add('swiper-wrapper');

    Array.from(this.slides).forEach(slide => {
      slide.classList.add('swiper-slide');
    });
  }

  _removeSwiperMarkup() {
    this.root.classList.remove('swiper-container');
    this.container.classList.remove('swiper-wrapper');

    Array.from(this.slides).forEach(slide => {
      slide.classList.remove('swiper-slide');
    });
  }
}
