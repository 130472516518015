import Cookies from 'cookies-js';
import Bowser from 'bowser';
import Module from '../lib/module';
import ResponsiveBinding from '../lib/responsive-binding';
import { EventAPI } from '../lib/event-helpers';

export default class BrowserSupportManager extends Module {
  static BANNER_SELECTOR = '[data-role="browser-support-banner"]';

  static COOKIE_BROWSER_SUPPORT_NAME = "hide-zeichenfabrik-browser-support-notice";

  setup() {
    this.eventApi = new EventAPI();

    this.banner = document.querySelector(this.constructor.BANNER_SELECTOR);

    this.bind();
  }

  get wasNoticeDismissed() {
    return Cookies.get(this.constructor.COOKIE_BROWSER_SUPPORT_NAME) === 'true';
  }

  get isBrowserOutdated() {
    // Using common browsers with versions after 2018-01-01 – and IE 11
    // Based on: https://browserl.ist/?q=since+2018-01-01%2C+IE+%3E%3D11

    const parser = Bowser.getParser(window.navigator.userAgent);

    // `Parser#satisfies` returns `undefined` for unspecified browsers
    // Undefined browsers are not considered as outdated
    // therefore `undefined` should return `false`
    return !!parser.satisfies({
      chrome: '<64',
      firefox: '<58',
      opera: '<50',
      windows: {
        ie: '<11',
        edge: '<17',
      },
      macos: {
        safari: '<11.1',
      },
      mobile: {
        safari: '<11.0',
        android: '<60',
        baidu: '<7.12',
        samsung_internet: '<8.2',

        // No proper detection for UC Browser at the moment
        // https://github.com/lancedikson/bowser/issues/255
        // uc: '<11.8',
      },
    });
  }

  destroy() {
    this.unbind();
  }

  activate() {
    this._debug('BrowserSupportManager#activate');

    this.bindActions();

    if (this.isBrowserOutdated && !this.wasNoticeDismissed) {
      this.showBanner();
    }
  }

  deactivate() {
    this._debug('BrowserSupportManager#deactivate');

    this.unbindActions();
    this.hideBanner();
  }

  bind() {
    this.eventApi = new EventAPI();

    this.binding = new ResponsiveBinding(this.element.getAttribute('data-breakpoint-limit'), {
      match: this.activate.bind(this),
      unmatch: this.deactivate.bind(this),
    });

    this.binding.bindAll();
  }

  unbind() {
    this.binding.unbindAll();
  }

  bindActions() {
    this.eventApi.on(this.element, 'click', '[data-action="dismiss-notice"]', (event) => {
      this.dismissBanner();
      this.hideBanner();
    });
  }

  unbindActions() {
    this.eventApi.off(document, 'click', '[data-action="dismiss-notice"]');
  }

  showBanner() {
    this._debug('BrowserSupportManager#showBanner');

    if (this.banner) {
      this.banner.style.display = 'block';
    }
  }

  hideBanner() {
    this._debug('BrowserSupportManager#hideBanner');

    if (this.banner) {
      this.banner.style.display = 'none';
    }
  }

  dismissBanner() {
    this._debug('BrowserSupportManager#dismissBanner');

    const oneWeekFromNow = new Date().getTime() + 7 * 24 * 60 * 60 * 1000;

    Cookies.set(this.constructor.COOKIE_BROWSER_SUPPORT_NAME, 'true', { expires: new Date(oneWeekFromNow) });
  }
}
