import { TweenLite, Power2, ScrollToPlugin } from 'gsap/all';
import { throttle } from 'throttle-debounce';
import Module from '../lib/module';
import { EventAPI } from '../lib/event-helpers';
import { elementInViewport, elementOffsetTop } from '../lib/utils';

// Make sure ScrollToPlugin is not dropped by tree shaking
const plugins = [ScrollToPlugin];

export default class OnpageAnchor extends Module {
  static FIXED_CLASS = 'is-fixed';
  static ONSCREEN_CLASS = 'is-onscreen';

  setup() {
    this.eventApi = new EventAPI();

    this.target = null;
    this.isFixed = this.element.classList.contains(this.constructor.FIXED_CLASS);
    this.passesFocusToTarget = this.element.getAttribute('data-focus-target') !== null;

    this.bind();
  }

  bind() {
    this.eventApi.on(this.element, 'click', '[data-action="scroll-to-href"]', event => {
      const eventTarget = event.target.closest('[data-action="scroll-to-href"]');
      const selector = eventTarget.getAttribute('href');
      this.target = document.querySelector(selector);

      if (this.target) {
        this.scrollToY(elementOffsetTop(this.target), () => {
          if (this.target && this.passesFocusToTarget) {
            this.target.setAttribute('tabindex', '-1');
            this.target.focus();
            this.target.removeAttribute('tabindex');
          }
        });

        event.preventDefault();
        return false;
      }
    });

    if (this.isFixed) {
      const selector = this.element.querySelector('[data-action="scroll-to-href"]').getAttribute('href');
      this.target = document.querySelector(selector);

      this.eventApi.on(window, 'scroll', throttle(100, event => {
        this.updateVisibility();
      }));

      this.eventApi.on(window, 'resize', throttle(100, event => {
        this.updateVisibility();
      }));

      this.updateVisibility();
    }
  }

  unbind() {
    this.eventApi.off(this.element, 'click', '[data-action="scroll-to-href"]');

    if (this.isFixed) {
      this.eventApi.off(window, 'scroll');
      this.eventApi.off(window, 'resize');
    }
  }

  updateVisibility() {
    this._debug('OnpageAnchor#updateVisibility');

    if (!this.target) { return; }

    const isTargetInViewport = elementInViewport(this.target, 1/3);

    if (isTargetInViewport) {
      this.element.classList.remove(this.constructor.ONSCREEN_CLASS);
    } else {
      this.element.classList.add(this.constructor.ONSCREEN_CLASS);
    }
  }

  scrollToY(y, callback) {
    this._debug('OnpageAnchor#scrollToY');

    TweenLite.to(window, 1, {
      scrollTo: {
        y: y,
        autoKill: false,
      },
      ease: Power2.easeInOut,
      onComplete: callback || function() {},
    });
  }

  destroy() {
    this.unbind();
  }
}
