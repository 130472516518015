import { TimelineLite, Power2, CSSPlugin, ColorPropsPlugin } from 'gsap/all';
import Module from '../lib/module';
import { EventAPI } from '../lib/event-helpers';

// Make sure CSSPlugin are not dropped by tree shaking
const plugins = [CSSPlugin, ColorPropsPlugin];

export default class Modal extends Module {
  static ESC_KEY_CODE = 27;
  static LAYER_SELECTOR = '[data-role="modal-layer"]';
  static BODY_SELECTOR = '[data-role="modal-body"]';

  static ONSCREEN_CLASS = 'is-onscreen';

  setup() {
    this.eventApi = new EventAPI();

    this.layer = this.element.querySelector(this.constructor.LAYER_SELECTOR);
    this.body = this.element.querySelector(this.constructor.BODY_SELECTOR);

    this.bind();
  }

  bind() {
    const openModalCallback = event => {
      const target = event.target.closest('[data-action="open-modal"]');

      if (target.getAttribute('data-modal-id') !== this.element.getAttribute('id')) {
        return;
      }

      if (['a', 'button'].includes(target.tagName.toLowerCase()) && event.type === 'click') {
        event.preventDefault();
      }

      this.open();
    };

    this.eventApi.on(document, 'click', '[data-action="open-modal"]', openModalCallback);

    this.eventApi.on(document, 'ajax:before', '[data-remote][data-action="open-modal"]', openModalCallback);

    this.eventApi.on(document, 'click', '[data-action="close-modal"]', event => {
      const target = event.target.closest('[data-action="close-modal"]');

      if (this.element.contains(target) || target.getAttribute('data-modal-id') === this.element.getAttribute('id')) {
        this.close();
      }
    });
  }

  unbind() {
    this.eventApi.off(document, 'click', '[data-action="open-modal"]');
    this.eventApi.off(document, 'ajax:before', '[data-remote][data-action="open-modal"]');
    this.eventApi.off(document, 'click', '[data-action="close-modal"]');
  }

  open() {
    this._debug('Modal#open');

    this.eventApi.on(document, 'keyup', event => {
      if (event.keyCode === this.constructor.ESC_KEY_CODE) {
        this.close();
      }
    });

    this.eventApi.on(this.element, 'click', event => {
      if (!this.layer.contains(event.target)) {
        this.close();
      }
    });

    const timeline = new TimelineLite({ paused: true });
    timeline.add(() => {
      document.documentElement.style.overflow = 'hidden';
      this.element.classList.add(this.constructor.ONSCREEN_CLASS);
    });
    timeline.from(this.element, 0.4, { backgroundColor: 'transparent' });
    timeline.from(this.layer, 0.3, { opacity: 0, y: '100px', ease: Power2.easeOut });
    timeline.set(this.element, { clearProps: 'all' });
    timeline.set(this.layer, { clearProps: 'all' });

    timeline.play();
  }

  close() {
    this._debug('Modal#close');

    this.eventApi.off(document, 'keyup');
    this.eventApi.off(this.element, 'click');

    const timeline = new TimelineLite({ paused: true });
    timeline.to(this.layer, 0.3, { opacity: 0, y: '-100px', ease: Power2.easeIn });
    timeline.to(this.element, 0.4, { backgroundColor: 'transparent' });
    timeline.set(this.element, { clearProps: 'all' });
    timeline.set(this.layer, { clearProps: 'all' });
    timeline.add(() => {
      document.documentElement.style.overflow = '';
      this.element.classList.remove(this.constructor.ONSCREEN_CLASS);
    });

    timeline.play();
  }

  destroy() {
    this.close();
    this.unbind();
  }
}
