import Module from '../lib/module';
import { EventAPI } from '../lib/event-helpers';

export default class FormGroup extends Module {
  static ALL_INPUT_SELECTOR = 'input[type="text"], input[type="email"], input[type="url"], input[type="tel"], input[type="password"], textarea, input[type="checkbox"], input[type="radio"], select, input[type="date"]';
  static TEXT_INPUT_SELECTOR = 'input[type="text"], input[type="email"], input[type="url"], input[type="tel"], input[type="password"], textarea, input[type="date"]';
  static CONTROL_INPUT_SELECTOR = 'input[type="checkbox"], input[type="radio"], select';

  static FOCUSED_CLASS = 'is-focused';
  static FILLED_CLASS = 'is-filled';

  setup() {
    this.eventApi = new EventAPI();

    this.inputs = this.element.querySelectorAll(this.constructor.ALL_INPUT_SELECTOR);
    this.isCheckable = ['checkbox', 'radio'].includes(this.inputs[0].type);

    if (this.inputs.length === 1) {
      this.input = this.inputs[0];
    }

    this.state = {
      isFocused: false,
      isFilled: false,
    };

    this.updateState();
    this.bind();
  }

  bind() {
    this.eventApi.on(this.element, 'focus', this.constructor.ALL_INPUT_SELECTOR, event => {
      this.focus();
    }, true);

    this.eventApi.on(this.element, 'blur', this.constructor.ALL_INPUT_SELECTOR, event => {
      this.blur();
    }, true);

    this.eventApi.on(this.element, 'input', this.constructor.TEXT_INPUT_SELECTOR, event => {
      this.updateState(['isFilled']);
    });

    this.eventApi.on(this.element, 'change', this.constructor.CONTROL_INPUT_SELECTOR, event => {
      this.updateState(['isFilled']);
    });
  }

  unbind() {
    this.eventApi.off(this.element, 'focus', this.constructor.ALL_INPUT_SELECTOR, null, true);
    this.eventApi.off(this.element, 'blur', this.constructor.ALL_INPUT_SELECTOR, null, true);
    this.eventApi.off(this.element, 'input', this.constructor.TEXT_INPUT_SELECTOR);
    this.eventApi.off(this.element, 'change', this.constructor.CONTROL_INPUT_SELECTOR);
  }

  focus() {
    this._debug('FormGroup#focus');

    if (this.state.isFocused) { return; }

    if (this.input && this.input !== document.activeElement) {
      this.input.focus();
    }

    this.element.classList.add(this.constructor.FOCUSED_CLASS);
    this.state.isFocused = true;
  }

  blur() {
    this._debug('FormGroup#blur');

    if (!this.state.isFocused) { return; }

    if (this.input && this.input === document.activeElement) {
      this.input.blur();
    }

    this.element.classList.remove(this.constructor.FOCUSED_CLASS);
    this.state.isFocused = false;
  }

  fill(value) {
    this._debug('FormGroup#fill');

    if (this.state.isFilled) { return; }

    if (this.input && this.isCheckable && !this.input.checked) {
      this.input.checked = true;
    } else if (this.input && value && this.input.value !== value) {
      this.input.value = value;
    }

    this.element.classList.add(this.constructor.FILLED_CLASS);
    this.state.isFilled = true;
  }

  clear() {
    this._debug('FormGroup#clear');

    if (!this.state.isFilled) { return; }

    if (this.input && this.isCheckable && this.input.checked) {
      this.input.checked = false;
    } else if (this.input && this.input.value.trim().length > 0) {
      this.input.value = '';
    }

    this.element.classList.remove(this.constructor.FILLED_CLASS);
    this.state.isFilled = false;
  }

  updateState(attributes) {
    this._debug('FormGroup#updateState');

    attributes = attributes || Object.keys(this.state);

    attributes.forEach(attribute => {
      switch (attribute) {
        case 'isFocused':
          this._updateFocusedState();
          break;
        case 'isFilled':
          this._updateFilledState();
          break;
      }
    });
  }

  _updateFilledState() {
    let isFilled;

    if (this.isCheckable) {
      isFilled = Array.from(this.inputs).some(input => input.checked);
    } else {
      isFilled = Array.from(this.inputs).some(input => input.value.trim().length > 0 || input.getAttribute('placeholder'));
    }

    if (!this.state.isFilled && isFilled) {
      if (this.input) {
        this.fill(this.input.value)
      } else {
        this.fill();
      }
    } else if (this.state.isFilled && !isFilled) {
      this.clear();
    }
  }

  _updateFocusedState() {
    const isFocused = Array.from(this.inputs).some(input => input === document.activeElement);

    if (!this.state.isFocused && isFocused) {
      this.focus();
    } else if (this.state.isFocused && !isFocused) {
      this.blur();
    }
  }

  destroy() {
    this.unbind();
  }
}
