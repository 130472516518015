import Module from '../lib/module';
import ResponsiveBinding from '../lib/responsive-binding';
import { EventAPI } from '../lib/event-helpers';

export default class TabNavigation extends Module {
  static ITEM_SELECTOR = '[data-role="tab-item"]';

  static ACTIVE_CLASS = 'is-active';

  setup() {
    this.historyMode = 'replaceState'; // options: 'replaceState', 'pushState'

    this.items = this.element.querySelectorAll(this.constructor.ITEM_SELECTOR);

    const panelsSelector = Array.from(this.items).map(item => item.getAttribute('href')).join(',');
    this.panels = document.querySelectorAll(panelsSelector);

    this.element.setAttribute('data-turbolinks', 'false');

    this.bind();
  }

  destroy() {
    this.unbind();
  }

  bind() {
    this.eventApi = new EventAPI();

    this.binding = new ResponsiveBinding(this.element.getAttribute('data-breakpoint-limit'), {
      setup: () => {
        Array.from(this.panels).forEach(item => {
          if (item.classList.contains('is-hidden-by-default')) {
            item.classList.remove(this.constructor.ACTIVE_CLASS);
          } else {
            item.classList.add(this.constructor.ACTIVE_CLASS);
          }
        });
      },
      match: this.activate.bind(this),
      unmatch: this.deactivate.bind(this),
    });

    this.binding.bindAll();
  }

  unbind() {
    this.binding.unbindAll();
  }

  bindActions() {
    this.eventApi.on(this.element, 'click', '[data-action="activate-tab"]', event => {
      event.preventDefault();

      const href = event.target.getAttribute('href');
      this.activateTab(href.match(/^#(.*)/)[1]);
    });
  }

  unbindActions() {
    this.eventApi.off(this.element, 'click', '[data-action="activate-tab"]');
  }

  activate() {
    this._debug('TabNavigation#activate');

    Array.from(this.items).forEach(item => {
      item.classList.remove(this.constructor.ACTIVE_CLASS);
    });

    Array.from(this.panels).forEach(item => {
      item.classList.remove(this.constructor.ACTIVE_CLASS);
    });

    this.updateFromLocation(window.location);
    this.bindActions();
  }

  deactivate() {
    this._debug('TabNavigation#deactivate');

    this.unbindActions();

    Array.from(this.items).forEach(item => {
      const hash = item.getAttribute('href');
      const panel = document.querySelector(hash);

      item.removeAttribute('aria-selected');

      panel.classList.add(this.constructor.ACTIVE_CLASS);
      panel.removeAttribute('aria-hidden');
    });

    Array.from(this.panels).forEach(item => {
      if (item.classList.contains('is-hidden-by-default')) {
        item.classList.remove(this.constructor.ACTIVE_CLASS);
      } else {
        item.classList.add(this.constructor.ACTIVE_CLASS);
      }
    });
  }

  updateFromLocation(location) {
    const currentHash = location.hash;
    const firstItem = this.items[0];
    const matchingItem = Array.from(this.items).find(item => item.getAttribute('href') === currentHash);

    if (matchingItem) {
      this.activateTab(matchingItem.getAttribute('href').match(/^#(.*)/)[1], false);
    } else {
      this.activateTab(firstItem.getAttribute('href').match(/^#(.*)/)[1], false);
    }
  }

  activateTab(id, updatesLocation = true) {
    this._debug('TabNavigation#activateTab');

    const item = Array.from(this.items).find(item => item.getAttribute('href') === `#${id}` );

    if (!item) {
      throw new Error(`Cannot activate tab with ID '${id}' – no item matches ID.`);
    } else if (item.classList.contains(this.constructor.ACTIVE_CLASS)) {
      return;
    }

    const panel = document.querySelector(`#${id}`);

    Array.from(this.items).forEach(item => {
      item.classList.remove(this.constructor.ACTIVE_CLASS);
      item.setAttribute('aria-selected', false);
    });

    item.classList.add(this.constructor.ACTIVE_CLASS);
    item.setAttribute('aria-selected', true);

    Array.from(this.panels).forEach(panel => {
      panel.classList.remove(this.constructor.ACTIVE_CLASS);
      panel.setAttribute('aria-hidden', true);
    });

    panel.classList.add(this.constructor.ACTIVE_CLASS);
    panel.setAttribute('aria-hidden', false);

    if (updatesLocation && (this.historyMode in window.history)) {
      // Pass `window.history.state` to let turbolinks handle navigating back/forward
      window.history[this.historyMode](window.history.state, null, `${window.location.pathname}#${id}`);
    }
  }
}
