import Module from '../lib/module';
import { EventAPI } from '../lib/event-helpers';

export default class ParticipantForm extends Module {
  static ALL_INPUT_SELECTOR = 'input[type="text"], input[type="email"], input[type="url"], input[type="tel"], input[type="password"], textarea, input[type="checkbox"], input[type="radio"], select';
  static TEXT_INPUT_SELECTOR = 'input[type="text"], input[type="email"], input[type="url"], input[type="tel"], input[type="password"], textarea';
  static CONTROL_INPUT_SELECTOR = 'input[type="checkbox"], input[type="radio"], select';

  static NAME_PLACEHOLDER_SELECTOR = '[data-role="participant-name-placeholder"]';
  static EMAIL_PLACEHOLDER_SELECTOR = '[data-role="participant-email-placeholder"]';

  static EXPANDED_CLASS = 'is-expanded';
  static COLLAPSED_CLASS = 'is-collapsed';

  setup() {
    this.eventApi = new EventAPI();

    this.inputs = this.element.querySelectorAll(this.constructor.ALL_INPUT_SELECTOR);
    this.namePlaceholder = this.element.querySelector(this.constructor.NAME_PLACEHOLDER_SELECTOR);
    this.emailPlaceholder = this.element.querySelector(this.constructor.EMAIL_PLACEHOLDER_SELECTOR);

    this.bind();
    this.updateBindings();
  }

  bind() {
    this.eventApi.on(this.element, 'click', '[data-action="expand-participant-form"]', event => {
      const target = event.target.closest('[data-action="expand-participant-form"]');

      if (target.tagName.toLowerCase() === 'a') {
        event.preventDefault();
      }

      this.expand();
    });

    this.eventApi.on(this.element, 'click', '[data-action="collapse-participant-form"]', event => {
      const target = event.target.closest('[data-action="collapse-participant-form"]');

      if (target.tagName.toLowerCase() === 'a') {
        event.preventDefault();
      }

      this.collapse();
    });

    this.eventApi.on(this.element, 'input', this.constructor.TEXT_INPUT_SELECTOR, event => {
      this.updateBindings();
    });

    this.eventApi.on(this.element, 'change', this.constructor.CONTROL_INPUT_SELECTOR, event => {
      this.updateBindings();
    });
  }

  unbind() {
    this.eventApi.off(this.element, 'click', '[data-action="expand-participant-form"]');
    this.eventApi.off(this.element, 'click', '[data-action="collapse-participant-form"]');
    this.eventApi.off(this.element, 'input', this.constructor.TEXT_INPUT_SELECTOR);
    this.eventApi.off(this.element, 'change', this.constructor.CONTROL_INPUT_SELECTOR);
  }

  _setInitialState() {
    if (this.element.classList.contains(this.constructor.COLLAPSED_CLASS)) {
      this.collapse(0);
    } else {
      this.expand(0);
    }
  }

  expand(duration) {
    this._debug('ParticipantForm#expand');

    if (this.element.classList.contains(this.constructor.EXPANDED_CLASS) && duration !== 0) {
      return;
    }

    this.element.classList.remove(this.constructor.COLLAPSED_CLASS);
    this.element.classList.add(this.constructor.EXPANDED_CLASS);
  }

  collapse(duration) {
    this._debug('ParticipantForm#collapse');

    if (this.element.classList.contains(this.constructor.COLLAPSED_CLASS) && duration !== 0) {
      return;
    }

    this.element.classList.remove(this.constructor.EXPANDED_CLASS);
    this.element.classList.add(this.constructor.COLLAPSED_CLASS);
  }

  updateBindings() {
    const nameInputs = Array.from(this.inputs).filter(input => input.name.match(/(salutation|forename|surname)/) !== null);
    const emailInputs = Array.from(this.inputs).filter(input => input.name.match(/(email)/) !== null);

    this.namePlaceholder.textContent = nameInputs.map(input => input.value.trim()).join(' ');
    this.emailPlaceholder.textContent = emailInputs.map(input => input.value.trim()).join(' ');
  }

  destroy() {
    this.unbind();
  }
}
