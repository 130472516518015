import enquire from 'enquire.js';
import Breakpoint from '../lib/breakpoint';

export default class ResponsiveBinding {
  constructor(breakpointLimits, handler) {
    let breakpoints;

    if (breakpointLimits && breakpointLimits.trim().length > 0) {
      breakpoints = (breakpointLimits || '').split(' ').map(breakpoint => breakpoint.trim());
    } else {
      breakpoints = [];
    }

    this.handler = handler || {};
    this.rules = [];

    breakpoints.forEach(breakpoint => {
      this.addRule(breakpoint);
    });
  }

  bindAll() {
    if (this.rules.length > 0) {
      this.rules.forEach(rule => {
        this.bindRule(rule);
      });
    } else {
      this.handler.match();
    }
  }

  unbindAll() {
    if (this.rules.length > 0) {
      this.rules.forEach(rule => {
        this.unbindRule(rule);
      });
    } else {
      this.handler.unmatch();
    }
  }

  addRule(breakpoint) {
    const name = breakpoint.split(':')[0];
    const minMax = breakpoint.split(':')[1];

    this.rules.push({
      query: Breakpoint.mediaQueryFor(name, minMax),
      handler: this.handler,
    });
  }

  bindRule(rule) {
    enquire.register(rule.query, rule.handler);
  }

  unbindRule(rule) {
    enquire.unregister(rule.query, rule.handler);
  }
}
