import Module from '../lib/module';
import { EventAPI } from '../lib/event-helpers';

export default class SelectControl extends Module {
  static LABEL_SELECTOR = '[data-role="select-label"]';
  static INPUT_SELECTOR = '[data-role="select-input"]';

  setup() {
    this.label = this.element.querySelector(this.constructor.LABEL_SELECTOR);
    this.input = this.element.querySelector(this.constructor.INPUT_SELECTOR);

    this.eventApi = new EventAPI();

    const selectedOption = this.input.querySelector('option:checked') || this.input.querySelector('option');
    this.updateLabel(selectedOption);

    this.bind();
  }

  bind() {
    this.eventApi.on(this.element, 'change', '[data-action="select-option"]', event => {
      const target = event.target.closest('[data-action="select-option"]');

      this.updateLabel(target.querySelector('option:checked'));
    });
  }

  updateLabel(option) {
    this._debug('SelectControl#updateLabel');

    const lines = option.getAttribute('data-lines').split("\n") || option.textContent;

    this.label.innerHTML = lines.join('<br>');
  }

  unbind() {
    this.eventApi.off(this.element, 'change', '[data-action="select-option"]');
  }

  destroy() {
    this.unbind();
  }
}
