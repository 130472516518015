import { throttle } from 'throttle-debounce';
import Module from '../lib/module';
import { EventAPI } from '../lib/event-helpers';
    // If we have a string, this is skipped.
import { makeRequest, elementInViewport, elementOffsetTop } from '../lib/utils';

export default class LazyLoadedContent extends Module {
  static CONTENT_CONTAINER_SELECTOR = '[data-role="lazy-content-container"]';
  static LOAD_ACTION_SELECTOR = '[data-role="lazy-content-action"]';
  static LOADING_INDICATOR_SELECTOR = '[data-role="lazy-content-loading"]';

  static HIDDEN_CLASS = 'is-hidden';

  setup() {
    this.eventApi = new EventAPI();

    this.sourceUrl = this.element.getAttribute('data-source-url');
    this.loadsOnScroll = this.element.getAttribute('data-load-on-scroll') === 'true';
    this.contentContainer = this.element.querySelector(this.constructor.CONTENT_CONTAINER_SELECTOR);
    this.loadAction = this.element.querySelector(this.constructor.LOAD_ACTION_SELECTOR);
    this.loadingIndicator = this.element.querySelector(this.constructor.LOADING_INDICATOR_SELECTOR);

    this.loadingIndicator.classList.add(this.constructor.HIDDEN_CLASS);

    this.page = 1;
    this.pageSize = parseInt(this.element.getAttribute('data-page-size'), 10) || 10;

    // Distance to bottom before new items are fetched (on scroll)
    // e.g. factor of 2 means new content is fetched when distance from current scroll position
    // to the bottom is by 2 times the window height or less
    this.scrollDistanceFactor = 1.5;

    this.bind();
  }

  bind() {
    this.eventApi.on(this.element, 'click', '[data-action="load-more"]', event => {
      this.fetch();
    });

    if (this.loadsOnScroll) {
      this.eventApi.on(window, 'scroll', throttle(100, event => {
        this.update();
      }));

      this.eventApi.on(window, 'resize', throttle(100, event => {
        this.update();
      }));

      this.update();
    }
  }

  unbind() {
    this.eventApi.off(this.element, 'click', '[data-action="load-more"]');

    if (this.loadsOnScroll) {
      this.eventApi.off(window, 'scroll');
      this.eventApi.off(window, 'resize');
    }
  }

  get previousPage() {
    return Math.max(1, this.page - 1);
  }

  get nextPage() {
    return this.page + 1;
  }

  update() {
    this._debug('LazyLoadedContent#update');

    const bounds = this.element.getBoundingClientRect();
    const windowHeight = Math.round(window.innerHeight || document.documentElement.clientHeight);

    if (bounds.bottom < (windowHeight * this.scrollDistanceFactor)) {
      this.fetch();
    }
  }

  fetch() {
    this._debug('LazyLoadedContent#fetch');

    if (this.requestIsPending) { return; }

    this.requestIsPending = true;

    this.loadingIndicator.classList.remove(this.constructor.HIDDEN_CLASS);
    this.loadAction.classList.add(this.constructor.HIDDEN_CLASS);

    makeRequest({
      type: 'GET',
      url: this.sourceUrl,
      data: {
        page: this.nextPage,
        limit: this.pageSize,
      }
    }).then((response) => {
      const childCount = this.contentContainer.children.length;
      this.loadingIndicator.classList.add(this.constructor.HIDDEN_CLASS);
      this.contentContainer.insertAdjacentHTML('beforeend', response);

      // Just re-display load action if number of children added matches `pageSize`
      if (this.contentContainer.children.length - childCount >= this.pageSize) {
        this.loadAction.classList.remove(this.constructor.HIDDEN_CLASS);
      }

      this.page = this.nextPage;
      this.requestIsPending = false;
    }).catch((error) => {
      this.loadingIndicator.classList.add(this.constructor.HIDDEN_CLASS);

      // No more items
      if (error.status === 404) {
        this.unbind();
      }

      this.requestIsPending = false;
    });
  }

  destroy() {
    this.unbind();

    this.loadingIndicator.classList.remove(this.constructor.HIDDEN_CLASS);
    this.loadAction.classList.remove(this.constructor.HIDDEN_CLASS);
  }
}
