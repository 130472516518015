import Module from '../lib/module';
import { EventAPI } from '../lib/event-helpers';

export default class ConditionalContent extends Module {
  static HIDDEN_CLASS = 'is-hidden';

  setup() {
    this.eventApi = new EventAPI();

    const dependentId = this.element.getAttribute('data-dependent-id');
    this.dependentField = document.querySelector(`#${dependentId}`);
    this.dependentValue = this.element.getAttribute('data-dependent-value');

    if (!this.dependentField) {
      throw Error(`There is no field that matches the passed ID '${dependentId}'`);
    } else {
      this.updateVisibility();
      this.bind();
    }
  }

  bind() {
    this.eventApi.on(document, 'change', `#${this.dependentField.id}`, event => {
      const triggeredManually = event.detail && event.detail.triggeredManually === true;
      this.updateVisibility(triggeredManually);
    });
  }

  unbind() {
    this.eventApi.off(document, 'change', `#${this.dependentField.id}`);
  }

  updateVisibility(suppressChangeOnRelatedFields) {
    var field = this.dependentField;
    var value = this.dependentValue;

    if (value) {
      if (value.split('|').indexOf(field.value) !== -1) {
        this.show();
      } else {
        this.hide();
      }
    } else if (field.tagName.toLowerCase() == 'input') {
      if (field.checked) {
        this.show();
      } else {
        this.hide();
      }

      if (field.type === 'radio' && !suppressChangeOnRelatedFields) {
        const inputSiblings = document.querySelectorAll(`input[type="radio"][name="${field.name}"]`);
        Array.from(inputSiblings).forEach(input => {
          if (input === field) { return; }
          this.eventApi.trigger(input, 'change', { triggeredManually: true });
        });
      }
    }
  }

  hide() {
    this._debug('ConditionalContent#hide');

    this.element.classList.add(this.constructor.HIDDEN_CLASS);

    const controls = this.element.querySelectorAll('input, select, textarea, button');
    Array.from(controls || []).forEach(element => element.disabled = true);

    this.isVisible = false;
  }

  show() {
    this._debug('ConditionalContent#show');

    this.element.classList.remove(this.constructor.HIDDEN_CLASS);

    const controls = this.element.querySelectorAll('input, select, textarea, button');
    Array.from(controls || []).forEach(element => element.disabled = false);

    this.isVisible = true;
  }

  destroy() {
    this.unbind();
  }
}
