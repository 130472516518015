import Module from '../lib/module';
import { EventAPI } from '../lib/event-helpers';

export default class ShareMenu extends Module {
  static BODY_SELECTOR = '[data-role="collapsible-body"]';

  static EXPANDED_CLASS = 'is-expanded';

  setup() {
    this.eventApi = new EventAPI();
    this.bind();
  }

  bind() {
    this.eventApi.on(this.element, 'click', '[data-action="share-on-facebook"]', event => {
      const target = event.target.closest('[data-action="share-on-facebook"]');

      event.preventDefault();

      this.shareOnFacebook({
        url: target.getAttribute('data-url') || target.getAttribute('href'),
        content: target.getAttribute('data-content'),
      });
    });

    this.eventApi.on(this.element, 'click', '[data-action="share-on-twitter"]', event => {
      const target = event.target.closest('[data-action="share-on-twitter"]');

      event.preventDefault();

      this.shareOnTwitter({
        url: target.getAttribute('data-url') || target.getAttribute('href'),
        content: target.getAttribute('data-content'),
      });
    });

    this.eventApi.on(this.element, 'click', '[data-action="print"]', event => {
      const target = event.target.closest('[data-action="print"]');
      const url = target.getAttribute('data-url');

      if (url) {
        event.preventDefault();
        this.print(url);
      }
    });
  }

  unbind() {
    this.eventApi.off(this.element, 'click', '[data-action="share-on-facebook"]');
    this.eventApi.off(this.element, 'click', '[data-action="share-on-twitter"]');
    this.eventApi.off(this.element, 'click', '[data-action="print"]');
  }

  print(url) {
    this._debug('SocialLink#print');

    const printWindow = window.open(url);
    printWindow.onload = function() { printWindow.print(); }
  }

  shareOnFacebook(data) {
    this._debug('SocialLink#shareOnFacebook');

    data = this._sanitizeData(data);
    const url = `http://www.facebook.com/sharer/sharer.php?u=${data['url']}&t=${data['content']}`;

    window.open(url, '', 'height=320, width=640, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no');
  }

  shareOnTwitter(data) {
    this._debug('SocialLink#shareOnTwitter');

    data = this._sanitizeData(data);
    const url = `https://twitter.com/intent/tweet?text=${data['content']}&url=${data['url']}&via=Zeichenfabrik`;

    window.open(url, '', 'height=320, width=640, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no');
  }

  _sanitizeData(data) {
    Object.keys(data).forEach(key => {
      switch (key) {
        case 'url':
          data[key] = window.encodeURIComponent(data[key]);
          break;
        case 'content':
          data[key] = window.encodeURIComponent((data[key] || '').replace(/\n|\r/g, '').replace(/\s+/g, ' '));
          break;
      }
    });

    return data;
  }

  destroy() {
    this.unbind();
  }
}
