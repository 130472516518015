import Module from '../lib/module';

export default class StickyHeader extends Module {
  static FLOATING_HEADER_CLASS = 'layout-header--floating';

  setup() {
    this.headerBar = this.element.querySelector('.header-bar')
    this.isHeaderBarTransparent = this.headerBar.classList.contains('header-bar--transparent');
    this.observer = null;

    const interceptionTarget = document.createElement("div");
    interceptionTarget.setAttribute('data-role', 'sticky-header-interception-target');
    if (this.isHeaderBarTransparent) {
      interceptionTarget.style.position = 'relative';
      interceptionTarget.style.top = '60px';
    }
    this.interceptionTarget = interceptionTarget;
    this.element.after(this.interceptionTarget);

    this.bind();
  }

  bind() {
    if (typeof IntersectionObserver !== 'function') { return; }

    this.observer = new IntersectionObserver(([entry]) => {
      this.element.classList.toggle(this.constructor.FLOATING_HEADER_CLASS, !entry.isIntersecting);

      if (this.isHeaderBarTransparent) {
        this.headerBar.classList.toggle('header-bar--transparent', entry.isIntersecting);
      }
    });

    this.observer.observe(this.interceptionTarget);
  }

  unbind() {
    if (typeof IntersectionObserver !== 'function') { return; }

    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  }

  destroy() {
    this.unbind();

    if (this.interceptionTarget) {
      this.interceptionTarget.remove();
      this.interceptionTarget = null;
    }
  }
}
