import Modal from './modal';
import Cookies from 'cookies-js';

export default class VisitorNotificationModal extends Modal {
  static COOKIE_NAME = "hide-zeichenfabrik-visitor-notification";

  setup() {
    this.notificationId = this.element.getAttribute('notification-id') || 'true';

    super.setup();

    if (!this.wasNotificationDismissed && this.element.getAttribute('modal-init') === 'open') {
      this.open();
    }
  }

  get wasNotificationDismissed() {
    return Cookies.get(this.constructor.COOKIE_NAME) === this.notificationId;
  }

  close() {
    super.close();

    const endOfToday = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    endOfToday.setHours(0);
    endOfToday.setMinutes(0);
    endOfToday.setSeconds(0);
    endOfToday.setMilliseconds(0);

    Cookies.set(this.constructor.COOKIE_NAME, this.notificationId, { expires: endOfToday });
  }
}
