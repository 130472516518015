import enquire from 'enquire.js';
import Swiper from 'swiper';
import Module from '../lib/module';
import Breakpoint from '../lib/breakpoint';

export default class SimpleSlider extends Module {
  static CONTAINER_SELECTOR = '[data-role="slider-slide-container"]';
  static SLIDE_SELECTOR = '[data-role="slider-slide"]';
  static PAGINATION_SELECTOR = '[data-role="slider-pagination"]';

  setup() {
    this.container = this.element.querySelector(this.constructor.CONTAINER_SELECTOR);
    this.slides = this.element.querySelectorAll(this.constructor.SLIDE_SELECTOR);
    this.pagination = this.element.querySelector(this.constructor.PAGINATION_SELECTOR);
    this.config = {
      i18n: JSON.parse(this.element.getAttribute('data-i18n')),
    };

    this.enquireRules = [
      {
        query: Breakpoint.mediaQueryFor('xs', 'max'),
        handler: {
          match: this._setupSwiper.bind(this),
          unmatch: this._destroySwiper.bind(this),
        },
      },
    ];

    this.bind();
  }

  bind() {
    this.enquireRules.forEach(rule => {
      enquire.register(rule.query, rule.handler);
    });
  }

  unbind() {
    this.enquireRules.forEach(rule => {
      enquire.unregister(rule.query, rule.handler);
    });
  }

  destroy() {
    this.unbind();
    this._destroySwiper();
  }

  _setupSwiper() {
    this._setupSwiperMarkup();

    let swiperOptions = {};
    if (this.config.i18n) {
      swiperOptions['a11y'] = {
        prevSlideMessage: this.config.i18n.prevSlide,
        nextSlideMessage: this.config.i18n.nextSlide,
        firstSlideMessage: this.config.i18n.firstSlide,
        lastSlideMessage: this.config.i18n.lastSlide,
        paginationBulletMessage: this.config.i18n.paginationBullet,
      };
    }

    const swiper = new Swiper(this.element, Object.assign({
      init: false,
      loop: false,
      spaceBetween: 0,
      pagination: {
        el: this.pagination,
        clickable: true,
      },
    }, swiperOptions));

    swiper.init();

    this.swiper = swiper;
  }

  _destroySwiper() {
    if (this.swiper) {
      this.swiper.destroy(true, true);
      this.swiper = null;
    }

    this._removeSwiperMarkup();
  }

  _setupSwiperMarkup() {
    this.element.classList.add('swiper-container');
    this.container.classList.add('swiper-wrapper');

    Array.from(this.slides).forEach(slide => {
      slide.classList.add('swiper-slide');
    });
  }

  _removeSwiperMarkup() {
    this.element.classList.remove('swiper-container');
    this.container.classList.remove('swiper-wrapper');

    Array.from(this.slides).forEach(slide => {
      slide.classList.remove('swiper-slide');
    });
  }
}
