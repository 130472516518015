import * as Sentry from '@sentry/browser';
import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';

import ModuleLoader from './lib/module-loader';
import BurgerMenu from './modules/burger-menu';
import StickyHeader from './modules/sticky-header';
import OnpageAnchor from './modules/onpage-anchor';
import Collapsible from './modules/collapsible';
import TabNavigation from './modules/tab-navigation';
import ConditionalContent from './modules/conditional-content';
import FormGroup from './modules/form-group';
import GiftCardPreview from './modules/gift-card-preview';
import CharacterCounter from './modules/character-counter';
import ShareMenu from './modules/share-menu';
import Modal from './modules/modal';
import PanelSlider from './modules/panel-slider';
import ProgramFilter from './modules/program-filter';
import SelectControl from './modules/select-control';
import ParticipantForm from './modules/participant-form';
import Autocomplete from './modules/autocomplete';
import RevealableContent from './modules/revealable-content';
import PanableContent from './modules/panable-content';
import DynamicHeightContent from './modules/dynamic-height-content';
import LazyLoadedContent from './modules/lazy-loaded-content';
import ReuseableContent from './modules/reuseable-content';
import CookieManager from './modules/cookie-manager';
import BrowserSupportManager from './modules/browser-support-manager';
import TeamEventsFilter from './modules/team-events-filter';
import ImageCarousel from './modules/image-carousel';
import FullscreenLightbox from './modules/fullscreen-lightbox';
import SimpleSlider from './modules/simple-slider';
import VisitorNotificationModal from './modules/visitor-notification-modal';

const isDevelopment = process.env.NODE_ENV === 'development';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.RAILS_ENV,
  whitelistUrls: [ /^https?:\/\/[^\/]*\.zeichenfabrik\.at/ ],
  debug: isDevelopment,
});

Rails.start();

Turbolinks.start();

const loader = new ModuleLoader({ debug: isDevelopment });

loader.register('burger-menu', BurgerMenu);
loader.register('sticky-header', StickyHeader);
loader.register('onpage-anchor', OnpageAnchor);
loader.register('collapsible', Collapsible);
loader.register('tab-navigation', TabNavigation);
loader.register('conditional-content', ConditionalContent);
loader.register('form-group', FormGroup);
loader.register('gift-card-preview', GiftCardPreview);
loader.register('character-counter', CharacterCounter);
loader.register('share-menu', ShareMenu);
loader.register('modal', Modal);
loader.register('panel-slider', PanelSlider);
loader.register('program-filter', ProgramFilter);
loader.register('select-control', SelectControl);
loader.register('participant-form', ParticipantForm);
loader.register('autocomplete', Autocomplete);
loader.register('revealable-content', RevealableContent);
loader.register('panable-content', PanableContent);
loader.register('dynamic-height-content', DynamicHeightContent);
loader.register('lazy-loaded-content', LazyLoadedContent);
loader.register('reuseable-content', ReuseableContent);
loader.register('cookie-manager', CookieManager);
loader.register('browser-support-manager', BrowserSupportManager);
loader.register('team-events-filter', TeamEventsFilter);
loader.register('image-carousel', ImageCarousel);
loader.register('fullscreen-lightbox', FullscreenLightbox);
loader.register('simple-slider', SimpleSlider);
loader.register('visitor-notification-modal', VisitorNotificationModal);

// Load modules manually if turbolinks is disabled or not supported
if (Turbolinks && !Turbolinks.supported) {
  loader.run();
}

document.addEventListener('turbolinks:load', function() {
  loader.run();
});

// Reset modules before new content is cached
document.addEventListener('turbolinks:before-cache', function() {
  loader.reset();
});

// Reset modules before new content is rendered
// This will only take effect if turbolink is configured without cache
// Otherwise the modules will already be reset
document.addEventListener('turbolinks:before-render', function() {
  loader.reset();
});

// Do not use Turbolinks for certain situations
// e.g. URLs like webcal://…
document.addEventListener('turbolinks:click', function(event) {
  const isWebcalProtocol = event.data.url.indexOf('webcal://') === 0;

  if (isWebcalProtocol) {
    event.preventDefault();
    return false;
  }
});

// Use ajax:send and check on request´s state manually
// because ajax:complete does not bubble to document when triggered on a remote form
document.addEventListener('ajax:send', function(event) {
  const request = event.detail[0];
  const onreadystatechangeBackup = request.onreadystatechange;

  request.onreadystatechange = function() {
    onreadystatechangeBackup();

    if (request.readyState === 4) {
      request.onreadystatechange = onreadystatechangeBackup;
      loader.run();
    }
  };
});
