import Module from '../lib/module';
import ResponsiveBinding from '../lib/responsive-binding';
import { EventAPI } from '../lib/event-helpers';

export default class ReuseableContent extends Module {
  setup() {
    const scopeSelector = this.element.getAttribute('data-scope');
    const targetSelector = this.element.getAttribute('data-target');

    if (scopeSelector) {
      this.scope = this.element.closest(scopeSelector);
    } else {
      this.scope = document.body;
    }

    this.target = this.scope.querySelector(targetSelector);

    this.bind();
  }

  destroy() {
    this.unbind();
  }

  activate() {
    this._debug('ReuseableContent#activate');

    this.bindActions();
    this.giveAway();
  }

  deactivate() {
    this._debug('ReuseableContent#deactivate');

    this.unbindActions();
    this.fetchBack();
  }

  bind() {
    this.eventApi = new EventAPI();

    this.binding = new ResponsiveBinding(this.element.getAttribute('data-breakpoint-limit'), {
      match: this.activate.bind(this),
      unmatch: this.deactivate.bind(this),
    });

    this.binding.bindAll();
  }

  unbind() {
    this.binding.unbindAll();
  }

  bindActions() {}

  unbindActions() {}

  giveAway() {
    this._debug('ReuseableContent#giveAway');

    if (!this.target) { return; }

    Array.from(this.element.children).forEach(child => {
      this.target.appendChild(child);
    })
  }

  fetchBack() {
    this._debug('ReuseableContent#fetchBack');

    if (!this.target) { return; }

    Array.from(this.target.children).forEach(child => {
      this.element.appendChild(child);
    })
  }
}
