import Cookies from 'cookies-js';
import Module from '../lib/module';
import ResponsiveBinding from '../lib/responsive-binding';
import { EventAPI } from '../lib/event-helpers';

export default class CookieManager extends Module {
  static NOTICE_SELECTOR = '[data-role="cookie-notice"]';

  static COOKIE_NOTICE_NAME = "hide-zeichenfabrik-cookie-notice";

  setup() {
    this.eventApi = new EventAPI();

    this.banner = document.querySelector(this.constructor.NOTICE_SELECTOR);

    this.bind();
  }

  get wasCookieNoticeDismissed() {
    return Cookies.get(this.constructor.COOKIE_NOTICE_NAME) === 'true';
  }

  destroy() {
    this.unbind();
  }

  activate() {
    this._debug('CookieManager#activate');

    this.bindActions();

    if (!this.wasCookieNoticeDismissed) {
      this.showBanner();
    }
  }

  deactivate() {
    this._debug('CookieManager#deactivate');

    this.unbindActions();
    this.hideBanner();
  }

  bind() {
    this.eventApi = new EventAPI();

    this.binding = new ResponsiveBinding(this.element.getAttribute('data-breakpoint-limit'), {
      match: this.activate.bind(this),
      unmatch: this.deactivate.bind(this),
    });

    this.binding.bindAll();
  }

  unbind() {
    this.binding.unbindAll();
  }

  bindActions() {
    this.eventApi.on(this.element, 'click', '[data-action="accept-cookies"]', (event) => {
      this.allowCookieUsage();
      this.hideBanner();
    });

    this.eventApi.on(this.element, 'click', '[data-action="decline-cookies"]', (event) => {
      this.declineCookieUsage();
      this.hideBanner();
    });
  }

  unbindActions() {
    this.eventApi.off(document, 'click', '[data-action="accept-cookies"]');
    this.eventApi.off(document, 'click', '[data-action="decline-cookies"]');
  }

  showBanner() {
    this._debug('CookieManager#showBanner');

    if (this.banner) {
      this.banner.style.display = 'block';
    }
  }

  hideBanner() {
    this._debug('CookieManager#hideBanner');

    if (this.banner) {
      this.banner.style.display = 'none';
    }
  }

  allowCookieUsage() {
    this._debug('CookieManager#allowCookieUsage');

    this.dismissNotice();

    // TODO: Make sure cookie usage is allowed
  }

  declineCookieUsage() {
    this._debug('CookieManager#declineCookieUsage');

    this.dismissNotice();

    // TODO: Make sure cookie usage is limited
  }

  dismissNotice() {
    const oneYearFromNow = new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000);

    Cookies.set(this.constructor.COOKIE_NOTICE_NAME, 'true', { expires: oneYearFromNow });
  }
}
