import { TweenLite, Power2, CSSPlugin, ScrollToPlugin } from 'gsap/all';
import { throttle } from 'throttle-debounce';
import Module from '../lib/module';
import ResponsiveBinding from '../lib/responsive-binding';
import { EventAPI } from '../lib/event-helpers';
import { elementInViewport, elementOffsetTop } from '../lib/utils';

// Make sure CSSPlugin and ScrollToPlugin are not dropped by tree shaking
const plugins = [CSSPlugin, ScrollToPlugin];

export default class Collapsible extends Module {
  static BODY_SELECTOR = '[data-role="collapsible-body"]';
  static GROUP_SELECTOR = '[data-role="collapsible-group"]';

  static EXPANDED_CLASS = 'is-expanded';
  static COLLAPSED_CLASS = 'is-collapsed';
  static EXPANDING_CLASS = 'is-expanding';
  static COLLAPSING_CLASS = 'is-collapsing';

  static DEFAULT_DURATION = 0.3;

  setup() {
    if (this.element.classList.contains(this.constructor.COLLAPSED_CLASS)) {
      this.initialState = 'COLLAPSED';
    } else if (this.element.classList.contains(this.constructor.EXPANDED_CLASS)) {
      this.initialState = 'EXPANDED';
    } else {
      this.initialState = null;
    }

    this.body = this.element.querySelector(this.constructor.BODY_SELECTOR);
    this.group = this.element.closest(this.constructor.GROUP_SELECTOR);

    this.eventScope = this.group || this.element;
    this.updatesLocation = this.element.getAttribute('data-updates-history') === 'true';

    this.bind();
  }

  destroy() {
    this.unbind();
  }

  bind() {
    this.eventApi = new EventAPI();

    this.binding = new ResponsiveBinding(this.element.getAttribute('data-breakpoint-limit'), {
      match: this.activate.bind(this),
      unmatch: this.deactivate.bind(this),
    });

    this.binding.bindAll();
  }

  unbind() {
    this.binding.unbindAll();
  }

  activate() {
    this.bindActions();
    this._initializeFromClassName();
  }

  deactivate() {
    this.unbindActions();
    this.reset();
  }

  bindActions() {
    this.eventApi.on(window, 'resize', throttle(100, (event) => {
      this._recalculate();
    }));

    this.eventApi.on(this.eventScope, 'click', '[data-action="toggle-collapsible"]', event => {
      const target = event.target.closest('[data-action="toggle-collapsible"]');
      const duration = target.getAttribute('data-duration');
      const actionTarget = this._getActionTarget(target);

      if (target.tagName.toLowerCase() === 'a') {
        event.preventDefault();
      }

      if (actionTarget == this.element) {
        this.toggle(duration);
      } else {
        this.collapse(duration);
      }
    });

    this.eventApi.on(this.eventScope, 'click', '[data-action="expand-collapsible"]', event => {
      const target = event.target.closest('[data-action="expand-collapsible"]');
      const duration = target.getAttribute('data-duration');
      const actionTarget = this._getActionTarget(target);

      if (target.tagName.toLowerCase() === 'a') {
        event.preventDefault();
      }

      if (actionTarget == this.element) {
        this.expand(duration);

        if (this.updatesLocation && ('pushState' in window.history)) {
          if (target.tagName.toLowerCase() === 'a' && window.location.toString() !== target.toString()) {
            // Pass `window.history.state` to let turbolinks handle navigating back/forward
            window.history.pushState(window.history.state, null, target.toString());
          }
        }
      } else {
        this.collapse(duration);
      }
    });

    this.eventApi.on(this.eventScope, 'click', '[data-action="collapse-collapsible"]', event => {
      const target = event.target.closest('[data-action="collapse-collapsible"]');
      const duration = target.getAttribute('data-duration');
      const actionTarget = this._getActionTarget(target);

      if (target.tagName.toLowerCase() === 'a') {
        event.preventDefault();
      }

      if (actionTarget == this.element) {
        this.collapse(duration);
      } else {
        this.collapse(duration);
      }
    });
  }

  unbindActions() {
    this.eventApi.off(window, 'resize');
    this.eventApi.off(this.eventScope, 'click', '[data-action="toggle-collapsible"]');
    this.eventApi.off(this.eventScope, 'click', '[data-action="expand-collapsible"]');
    this.eventApi.off(this.eventScope, 'click', '[data-action="collapse-collapsible"]');
  }

  _initializeFromClassName() {
    if (this.element.classList.contains(this.constructor.COLLAPSED_CLASS)) {
      this.collapse(0, false);
    } else {
      this.expand(0, false);
    }
  }

  _getActionTarget(element) {
    const targetSelector = element.getAttribute('data-target');

    if (targetSelector) {
      return document.querySelector(targetSelector);
    } else if (element.tagName.toLowerCase() === 'a') {
      return document.querySelector(element.getAttribute('href'));
    } else {
      return element.closest(this.selector);
    }
  }

  _sanitizeDuration(duration) {
    if (typeof duration === 'number') {
      return duration;
    } else if (typeof duration === 'string') {
      return parseFloat(duration, 10);
    } else {
      return this.constructor.DEFAULT_DURATION;
    }
  }

  _updateActionTrigger(isActive) {
    const elementId = this.element.getAttribute('id');
    const actionTrigger = elementId ? document.querySelector(`[href="#${elementId}"], [data-target="#${elementId}"]`) : null;
    const activeClass = actionTrigger ? actionTrigger.getAttribute('data-active-class'): null;

    if (!actionTrigger || !activeClass) { return; }

    if (isActive) {
      actionTrigger.classList.add(activeClass);
    } else {
      actionTrigger.classList.remove(activeClass);
    }
  }

  _recalculate() {
    if (this.element.classList.contains(this.constructor.EXPANDED_CLASS)) {
      TweenLite.set(this.body, { clearProps: 'height, paddingTop, paddingBottom' });
      TweenLite.from(this.body, 0, {
        height: window.getComputedStyle(this.element).minHeight,
        paddingTop: 0,
        paddingBottom: 0,
      });
    }
  }

  scrollIntoViewport() {
    const isCompletelyOutsideViewport = !elementInViewport(this.body, 0);

    if (isCompletelyOutsideViewport) {
      TweenLite.to(window, 0.5, {
        scrollTo: elementOffsetTop(this.body),
        ease: Power2.easeInOut
      });
    }
  }

  toggle(duration) {
    this._debug('Collapsible#toggle');

    if (this.element.classList.contains(this.constructor.COLLAPSED_CLASS)) {
      this.expand(...arguments);
    } else {
      this.collapse(...arguments);
    }
  }

  expand(duration, isUserInteraction = true) {
    this._debug('Collapsible#expand');

    duration = this._sanitizeDuration(duration);

    if (this.element.classList.contains(this.constructor.EXPANDED_CLASS) && duration > 0) {
      return;
    }

    this._updateActionTrigger(true);

    const minHeight = window.getComputedStyle(this.element).minHeight;

    this.element.classList.remove(this.constructor.COLLAPSED_CLASS);
    this.element.classList.add(this.constructor.EXPANDING_CLASS);

    TweenLite.set(this.body, { clearProps: 'height, paddingTop, paddingBottom' });
    TweenLite.from(this.body, duration, {
      height: minHeight,
      paddingTop: 0,
      paddingBottom: 0,
      ease: Power2.easeInOut,
      onComplete: () => {
        this.element.classList.remove(this.constructor.EXPANDING_CLASS);
        this.element.classList.add(this.constructor.EXPANDED_CLASS);

        if (isUserInteraction) {
          this.scrollIntoViewport();
        }
      }
    });
  }

  collapse(duration, isUserInteraction = true) {
    this._debug('Collapsible#collapse');

    duration = this._sanitizeDuration(duration);

    if (this.element.classList.contains(this.constructor.COLLAPSED_CLASS) && duration > 0) {
      return;
    }

    this._updateActionTrigger(false);

    const minHeight = window.getComputedStyle(this.element).minHeight;

    this.element.classList.add(this.constructor.COLLAPSING_CLASS);
    this.element.classList.remove(this.constructor.EXPANDED_CLASS);

    TweenLite.to(this.body, duration, {
      height: minHeight,
      paddingTop: 0,
      paddingBottom: 0,
      ease: Power2.easeInOut,
      onComplete: () => {
        this.element.classList.remove(this.constructor.COLLAPSING_CLASS);
        this.element.classList.add(this.constructor.COLLAPSED_CLASS);
      }
    });
  }

  reset() {
    TweenLite.set(this.body, { clearProps: 'height, paddingTop, paddingBottom' });

    this.element.classList.remove(this.constructor.COLLAPSING_CLASS);
    this.element.classList.remove(this.constructor.EXPANDING_CLASS);

    if (this.initialState === 'COLLAPSED') {
      this.element.classList.add(this.constructor.COLLAPSED_CLASS);
      this.element.classList.remove(this.constructor.EXPANDED_CLASS);
    } else if (this.initialState === 'EXPANDED') {
      this.element.classList.remove(this.constructor.COLLAPSED_CLASS);
      this.element.classList.add(this.constructor.EXPANDED_CLASS);
    } else {
      this.element.classList.remove(this.constructor.EXPANDED_CLASS);
      this.element.classList.remove(this.constructor.COLLAPSED_CLASS);
    }
  }
}
