import Module from '../lib/module';
import { EventAPI } from '../lib/event-helpers';

export default class CharaterCounter extends Module {
  static INPUT_SELECTOR = 'input, textarea';
  static OUTPUT_SELECTOR = '[data-role="counter-output"]';

  setup() {
    this.eventApi = new EventAPI();

    this.input = this.element.querySelector(this.constructor.INPUT_SELECTOR);
    this.output = this.element.querySelector(this.constructor.OUTPUT_SELECTOR);
    this.template = this.output.textContent;

    this.charactersPerLine = this.element.getAttribute('data-characters-per-line');
    this.maximumLength = this.input.getAttribute('maxlength');
    this.minimumLength = this.input.getAttribute('minlength');

    this.update();
    this.bind();
  }

  bind() {
    this.eventApi.on(this.element, 'keypress', this.constructor.INPUT_SELECTOR, event => {
      if (!event.charCode || event.charCode === 0) {
        return true;
      }

      if (this.charactersLeft === 0) {
        event.preventDefault();
        return false;
      }
    });

    this.eventApi.on(this.element, 'input', this.constructor.INPUT_SELECTOR, event => {
      this.update();
    });
  }

  unbind() {
    this.eventApi.off(this.element, 'keypress', this.constructor.INPUT_SELECTOR);
    this.eventApi.off(this.element, 'input', this.constructor.INPUT_SELECTOR);
  }

  update() {
    this._debug('CharaterCounter#update');

    this._countCharacters();

    const placeholders = {
      CHARACTERS_USED: this.charactersUsed,
      CHARACTERS_LEFT: this.charactersLeft,
      CHARACTERS_MISSING: this.charactersMissing,
      CHARACTERS_MAXIMUM: this.maximumLength,
      CHARACTERS_MINIMUM: this.minimumLength,
    };

    this.output.textContent = this.template.replace(/\[(.+)\]/g, (match, matchGroup) => {
      return placeholders[matchGroup];
    });
  }

  _countCharacters() {
    const lines = this.input.value.split('\n');
    const lastLine = lines[lines.length - 1];
    const charactersUsed = lines.reduce((previous, current) => {
      if (this.charactersPerLine && current !== lastLine) {
        return previous + Math.max(this.charactersPerLine, current.length);
      } else {
        return previous + current.length;
      }
    }, 0);

    this.charactersUsed = charactersUsed;

    if (this.maximumLength) {
      this.charactersLeft = Math.max(this.maximumLength - charactersUsed, 0);
    }

    if (this.minimumLength) {
      this.charactersMissing = Math.max(this.minimumLength - charactersUsed, 0);
    }
  }

  destroy() {
    this.unbind();
  }
}

