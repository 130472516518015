import Module from '../lib/module';
import ResponsiveBinding from '../lib/responsive-binding';
import { EventAPI } from '../lib/event-helpers';

export default class RevealableContent extends Module {
  static HIDDEN_CLASS = 'is-hidden';
  static REVEALED_CLASS = 'is-revealed';

  setup() {
    const itemSelector = this.element.getAttribute('data-item-selector');
    this.items = this.element.querySelectorAll(itemSelector);

    this.bind();
  }

  destroy() {
    this.unbind();
  }

  bind() {
    this.eventApi = new EventAPI();

    this.binding = new ResponsiveBinding(this.element.getAttribute('data-breakpoint-limit'), {
      match: this.activate.bind(this),
      unmatch: this.deactivate.bind(this),
    });

    this.binding.bindAll();
  }

  unbind() {
    this.binding.unbindAll();
  }

  activate() {
    Array.from(this.items).filter(item => {
      const isVisible = this._isVisible(item);

      if (isVisible) {
        this._revealItem(item);
      }
    });

    this.bindActions();
  }

  deactivate() {
    this.revealedItems.forEach(item => item.classList.remove(this.constructor.REVEALED_CLASS));

    Array.from(this.element.querySelectorAll('[data-action="reveal-content"]')).forEach(actionTarget => {
      actionTarget.classList.remove(this.constructor.HIDDEN_CLASS);
    });

    this.unbindActions();
  }

  bindActions() {
    this.eventApi.on(this.element, 'click', '[data-action="reveal-content"]', event => {
      const target = event.target.closest('[data-action="reveal-content"]');
      const count = parseInt(target.getAttribute('data-items-per-action'), 10) || null;

      this.reveal(count);

      if (this.unrevealedItems.length === 0) {
        target.classList.add(this.constructor.HIDDEN_CLASS);
      }
    });
  }

  unbindActions() {
    this.eventApi.off(this.element, 'click', '[data-action="reveal-content"]');
  }

  reveal(count) {
    this._debug('RevealableContent#reveal');

    count = count || this.unrevealedItems.length;
    const itemsToReveal = this.unrevealedItems.slice(0, count);

    itemsToReveal.forEach(item => {
      this._revealItem(item);
    });
  }

  get revealedItems() {
    return Array.from(this.items).filter(item => item.classList.contains(this.constructor.REVEALED_CLASS));
  }

  get unrevealedItems() {
    return Array.from(this.items).filter(item => !item.classList.contains(this.constructor.REVEALED_CLASS));
  }

  _revealItem(element) {
    element.classList.add(this.constructor.REVEALED_CLASS);
  }

  _isVisible(element) {
    // Visibility check taken from https://makandracards.com/makandra/1339-check-whether-an-element-is-visible-or-hidden-with-javascript
    return element.offsetWidth > 0 && element.offsetHeight > 0;
  }
}
