import Swiper from 'swiper';
import { throttle } from 'throttle-debounce';
import Module from '../lib/module';
import Breakpoint from '../lib/breakpoint';
import { EventAPI } from '../lib/event-helpers';

export default class ImageCarousel extends Module {
  static ROOT_SELECTOR = '[data-role="slider-root"]';
  static CONTAINER_SELECTOR = '[data-role="slider-slide-container"]';
  static SLIDE_SELECTOR = '[data-role="slider-slide"]';
  static PAGINATION_SELECTOR = '[data-role="slider-pagination"]';
  static CONTROL_PREVIOUS_SELECTOR = '[data-role="slider-control-previous"]';
  static CONTROL_NEXT_SELECTOR = '[data-role="slider-control-next"]';

  setup() {
    this.root = this.element.querySelector(this.constructor.ROOT_SELECTOR);
    this.container = this.element.querySelector(this.constructor.CONTAINER_SELECTOR);
    this.slides = this.element.querySelectorAll(this.constructor.SLIDE_SELECTOR);
    this.pagination = this.element.querySelector(this.constructor.PAGINATION_SELECTOR);
    this.controlPrevious = this.element.querySelector(this.constructor.CONTROL_PREVIOUS_SELECTOR);
    this.controlNext = this.element.querySelector(this.constructor.CONTROL_NEXT_SELECTOR);
    this.loadedImagesCount = 0;
    this.config = {
      loop: this.element.getAttribute('data-loop') == 'true',
      grouped: this.element.getAttribute('data-grouped') == 'true',
      i18n: JSON.parse(this.element.getAttribute('data-i18n')),
    }

    this.eventApi = new EventAPI();

    this.bind();
  }

  bind() {
    const initialSlideCount = this.slides.length;
    this.loadedImagesCount = Array.from(this.container.querySelectorAll('img')).filter((image) => image.complete).length;

    this._setupSwiperMarkup();

    if (this.loadedImagesCount >= initialSlideCount) {
      this._bindSwiper();
    } else {
      this.eventApi.on(this.element, 'load', 'img', (event) => {
        this.loadedImagesCount++;

        if (this.loadedImagesCount == initialSlideCount) {
          this.eventApi.off(this.element, 'load', 'img', undefined, true);
          this._bindSwiper();
        }
      }, true);
    }

    this.eventApi.on(this.element, 'click', '[data-action="navigate-previous"]', event => {
      this.slidePrevious();
    });

    this.eventApi.on(this.element, 'click', '[data-action="navigate-next"]', event => {
      this.slideNext();
    });
  }

  unbind() {
    this.eventApi.off(this.element, 'click', '[data-action="navigate-next"]');
    this.eventApi.off(this.element, 'click', '[data-action="navigate-previous"]');

    this.eventApi.off(this.element, 'load', 'img', undefined, true);
    this._unbindSwiper();
    this._removeSwiperMarkup();
  }

  destroy() {
    this.unbind();
  }

  slideNext() {
    this._debug('ImageCarousel#slideNext');

    this.swiper.slideNext();
  }

  slidePrevious() {
    this._debug('ImageCarousel#slidePrevious');

    this.swiper.slidePrev();
  }

  _bindSwiper() {
    const breakpoints = {};
    let swiperOptions = {};

    if (this.config.grouped) {
      breakpoints[parseInt(Breakpoint.get('sm').minWidth, 10)] = {
        slidesPerGroup: 1.66,
        slidesPerView: 1.66,
      };
      breakpoints[parseInt(Breakpoint.get('md').minWidth, 10)] = {
        slidesPerGroup: 2.66,
        slidesPerView: 2.66,
      };
      breakpoints[parseInt(Breakpoint.get('lg').minWidth, 10)] = {
        slidesPerGroup: 3.66,
        slidesPerView: 3.66,
        freeMode: false,
      };
      breakpoints[parseInt(Breakpoint.get('xl').minWidth, 10)] = {
        slidesPerGroup: 4,
        slidesPerView: 4,
        freeMode: false,
      };
      swiperOptions = {
        slidesPerGroup: 1,
        slidesPerView: 1,
        breakpointsInverse: true,
        breakpoints: breakpoints,
        freeMode: true,
      };
    } else {
      swiperOptions = {
        slidesPerView: 'auto',
        centeredSlides: true,
        keyboard: { enabled: true },
      }
    }

    if (this.config.i18n) {
      swiperOptions['a11y'] = {
        prevSlideMessage: this.config.i18n.prevSlide,
        nextSlideMessage: this.config.i18n.nextSlide,
        firstSlideMessage: this.config.i18n.firstSlide,
        lastSlideMessage: this.config.i18n.lastSlide,
        paginationBulletMessage: this.config.i18n.paginationBullet,
      };
    }

    const swiper = new Swiper(this.root, Object.assign({
      init: false,
      speed: 500,
      loop: this.config.loop,
      spaceBetween: 16,
      pagination: {
        el: this.pagination,
        clickable: true,
      },
    }, swiperOptions));

    swiper.on('init slideChangeTransitionEnd', () => {
      if (swiper.params.loop) { return; }

      if (this.controlPrevious) {
        this.controlPrevious.disabled = swiper.isBeginning;
      }
      if (this.controlNext) {
        this.controlNext.disabled = swiper.isEnd;
      }
    });

    swiper.init();

    this.swiper = swiper;
  }

  _unbindSwiper() {
    if (this.swiper) {
      this.swiper.destroy(true, true);
      this.swiper = null;
    }
  }

  _setupSwiperMarkup() {
    this.root.classList.add('swiper-container');
    this.container.classList.add('swiper-wrapper');

    Array.from(this.slides).forEach(slide => {
      slide.classList.add('swiper-slide');
    });
  }

  _removeSwiperMarkup() {
    this.root.classList.remove('swiper-container');
    this.container.classList.remove('swiper-wrapper');

    Array.from(this.slides).forEach(slide => {
      slide.classList.remove('swiper-slide');
    });
  }
}
