import { throttle } from 'throttle-debounce';

class Breakpoint {
  constructor() {
    this._detectBreakpoints();
    this._update();

    window.addEventListener('resize', throttle(100, this._update.bind(this)));
  }

  get(name) {
    return this.breakpoints.find(breakpoint => breakpoint.name === name);
  }

  getPreviousOf(name) {
    const breakpoint = this.breakpoints.find(breakpoint => breakpoint.name === name);
    const index = this.breakpoints.indexOf(breakpoint);

    if (index === 0) {
      return null;
    } else {
      return this.breakpoints[index - 1];
    }
  }

  getNextOf(name) {
    const breakpoint = this.breakpoints.find(breakpoint => breakpoint.name === name);
    const index = this.breakpoints.indexOf(breakpoint);

    if (index === this.breakpoints.length - 1) {
      return null;
    } else {
      return this.breakpoints[index + 1];
    }
  }

  isExactly(name) {
    return this.name === name;
  }

  isAtLeast(name) {
    return window.matchMedia(this.mediaQueryFor(name, 'min')).matches;
  }

  isAtMost(name) {
    return window.matchMedia(this.mediaQueryFor(name, 'max')).matches;
  }

  mediaQueryFor(name, minMax = 'min') {
    const breakpoint = this.breakpoints.find(breakpoint => breakpoint.name === name);

    if (!breakpoint) {
      throw new Error(`There is no breakpoint named '${name}'. Available breakpoints: ${this.breakpoints.map(breakpoint => breakpoint.name).join(', ')}. Current breakpoint: '${this.name}'.`);
    }

    return `(${minMax}-width: ${breakpoint[`${minMax}Width`]})`;
  }

  _update() {
    const beforeContent = this._getRootPseudoElementContent(':before');
    const value = this._parseContent(beforeContent);

    this.name = value[0];
    this.minWidth = value[1];
  }

  _detectBreakpoints() {
    const afterContent = this._getRootPseudoElementContent(':after');
    const breakpoints = this._parseContent(afterContent).map(value => {
      return {
        name: value[0],
        minWidth: value[1],
        maxWidth: undefined,
      };
    })

    this.breakpoints = breakpoints.map((breakpoint, index) => {
      const nextBreakpoint = breakpoints[index + 1];

      if (nextBreakpoint) {
        const unit = nextBreakpoint.minWidth.match(/^\d+([a-z]+)$/)[1];
        breakpoint.maxWidth = `${parseInt(nextBreakpoint.minWidth, 10) - 1}${unit}`;
      } else {
        breakpoint.maxWidth = null;
      }

      return breakpoint;
    });
  }

  _parseContent(content) {
    content = content.replace(/\"/g, '');

    if (content.indexOf('|') !== -1) {
      return content.split('|').map(value => this._parseContent(value));
    } else {
      return content.split(':').map(value => value.length === 0 ? null : value);
    }
  }

  _getRootPseudoElementContent(pseudoElement) {
    return window.getComputedStyle(document.body.parentElement, pseudoElement).getPropertyValue('content');
  }
}

export default new Breakpoint();
