import formDataEntries from 'form-data-entries';
import Module from '../lib/module';
import { EventAPI } from '../lib/event-helpers';

export default class TeamEventsFilter extends Module {
  setup() {
    this.eventApi = new EventAPI();

    this.bind();
  }

  bind() {
    this.eventApi.on(this.element, 'change', 'input, select, textarea', event => {
      // Elements without name will not be passed by URL
      // so they should not apply the filter when being changed
      if (event.target.matches('[name]')) {
        // Delay slightly in order to allow UI animations to proceed
        window.setTimeout(() => {
          this.apply();
        }, 200);
      }
    });
  }

  unbind() {
    this.eventApi.off(this.element, 'change', 'input, select, textarea');
  }

  apply() {
    this._debug('TeamEventsFilter#apply');

    const form = this.element.closest('form');
    const action = new URL(form.action);

    if (form) {
      const queryStringElements = [];

      for (const [key, value] of formDataEntries(form)) {
        queryStringElements.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
      }

      if (Turbolinks.supported) {
        Turbolinks.visit(action.pathname + '?' + queryStringElements.join('&') + action.hash);
      } else {
        window.location.href = action.pathname + '?' + queryStringElements.join('&') + action.hash;
      }
    }
  }

  reset() {
    this._debug('TeamEventsFilter#reset');

    const form = this.element.closest('form');

    if (Turbolinks.supported) {
      Turbolinks.visit(form.action);
    } else {
      window.location.href = form.action;
    }
  }

  destroy() {
    this.unbind();
  }
}
