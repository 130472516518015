import enquire from 'enquire.js';
import { throttle } from 'throttle-debounce';
import Module from '../lib/module';
import Breakpoint from '../lib/breakpoint';
import { EventAPI } from '../lib/event-helpers';

export default class DynamicHeightContent extends Module {
  setup() {
    this.eventApi = new EventAPI();

    this.enquireRules = [];
    const breakpoint = this.element.getAttribute('data-breakpoint-limit');

    if (breakpoint) {
      this.enquireRules.push({
        query: Breakpoint.mediaQueryFor(breakpoint.split(':')[0], breakpoint.split(':')[1]),
        handler: {
          match: this.activate.bind(this),
          unmatch: this.deactivate.bind(this),
        },
      });
    } else {
      this.activate();
    }

    this.bind();
  }

  activate() {
    this.bindActions();
    this.recalculate();
  }

  deactivate() {
    this.unbindActions();
    this.clear();
  }

  destroy() {
    this.unbind();

    if (this.enquireRules.length === 0) {
      this.deactivate();
    }
  }

  bind() {
    this.enquireRules.forEach(rule => {
      enquire.register(rule.query, rule.handler);
    });
  }

  unbind() {
    this.enquireRules.forEach(rule => {
      enquire.unregister(rule.query, rule.handler);
    });
  }

  bindActions() {
    this.eventApi.on(window, 'resize', throttle(100, (event) => {
      this.recalculate();
    }));

    this.eventApi.on(window, 'load', (event) => {
      this.recalculate();
    });
  }

  unbindActions() {
    this.eventApi.off(window, 'resize');
    this.eventApi.off(window, 'load');
  }

  recalculate() {
    this._debug('DynamicHeightContent#recalculate');

    let height;

    const initialInlineStyles = this.element.style.cssText;
    const measureStyles = [
      'visibility: hidden;',
      'position: absolute;',
      'height: auto !important;',
    ].join('');

    // Use cssText to make sure "!important" is applied
    this.element.style.cssText = initialInlineStyles + measureStyles;

    height = this.element.offsetHeight;

    this.element.style.cssText = initialInlineStyles;
    this.element.style.height = `${height}px`;
  }

  clear() {
    this._debug('DynamicHeightContent#recalculate');

    this.element.style.height = '';
  }
}
